import React from "react";


class DownloadAppLink extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isWebView: false
        }

    }

    checkIfWebView() {

    }


    render() {
        return (
            <div className="d-flex flex-row justify-content-center">
                <div className="w-50">
                    <a href={"https://play.google.com/store/apps/details?id=com.alinso.activity&hl=tr"}>
                        <img className="shadow rounded-2" width="150" src={"/img/google_play.png"}/>
                    </a>
                </div>
                <div className="w-50">
                     <a href={"https://apps.apple.com/tr/app/actizone/id1631011103?l=tr"}>
                        <img className="shadow rounded-2" width="150" src={"/img/app_store.png"}/>
                     </a>
                </div>
            </div>
        )
    }
}


export default DownloadAppLink;




