import React from "react";
import classnames from "classnames";
import Validator from "../../../util/Validator";
import InputMask from "react-input-mask";
import Security from "../../../security/Security";
import Globals from "../../../util/Globals";
import Select from "react-select";
import CityUtil from "../../../util/CityUtil";
import DownloadAppLink from "../../common/DownloadAppLink";

const axios = require('axios');


class RegisterMobile extends React.Component {
    constructor() {
        super();

        if (localStorage.getItem("jwtToken")) {
            Security.logout();
        }

        this.state = {
            name: "",
            surname: "",
            cities: [],
            city: null,
            cityIdTrns: null,
            email: "",
            phone: null,
            password: "",
            confirmPassword: "",
            gender: "UNSELECTED",
            referenceCode: "",
            isSubmitDisabled: false,
            registrationCompleted: false,
            userGuide: false,
            errors: {}
        };

        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.loadCities();
    }

    loadCities() {
        const self = this;
        axios.get(Globals.serviceUrl + 'city/all/')
            .then(function (response) {
                let result = CityUtil.setCitiesForSelect(response.data);
                self.setState({cities: result.cities});
                //  self.setState({city: result.cities[0]});
            })
            .catch(function (error) {
                alert(error);
            });
    }

    createUser(newUser) {
        let self = this;
        axios.post(Globals.serviceUrl + 'user/register', newUser)
            .then(function (response) {
                window.location = "/verifyPhone";
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
                self.setState({isSubmitDisabled: false});
            });
    }

    onSelectChange(e) {
        this.setState({city: e});
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.state.city == null) {
            alert("Yaşadığınız şehri seçmelisiniz!");
            return;
        }


        let phoneValidationResult = Validator.validatePhoneNumber(this.state.phone);

        if (!phoneValidationResult.valid) {
            let errorUpdated = {...this.state.errors}
            errorUpdated.phone = "Telefon numarası uygun formatta değil";
            this.setState({"errors": errorUpdated})
            return;
        }
        this.setState({isSubmitDisabled: true});
        const newUser = {
            name: this.state.name,
            surname: this.state.surname,
            city: this.state.city,
            email: this.state.email.trim(),
            phone: phoneValidationResult.phoneNumer,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            gender: this.state.gender,
            referenceCode: this.state.referenceCode,
            cityIdTrns: this.state.city.value
        };
        this.createUser(newUser);
    }

    render() {
        const {registrationCompleted} = this.state;
        const {errors} = this.state;

        return (
            <div className="container-fluid" style={{'backgroundColor': '#ffe5d0', 'min-height': '100vh'}}>
                {registrationCompleted && (
                    <div className={"registerCompletedMessage"}>
                        <h6>Kayıt Tamamlandı</h6>
                        <span>Bize katıldığın için teşekkür ederiz. </span><br/>
                        <a className={"color-white"} href={"/login"}>
                            <btn className="btn btn-success">Giriş İçin Tıkla</btn>
                        </a>
                    </div>
                )}
                <div className="d-flex justify-content-center" style={{'min-height': '100vh', 'padding-top': '50px'}}>
                    <div className="register_card">
                        <div className="d-flex justify-content-center">
                            <div className="register_logo_container">
                                <img src={"/img/ioslogo3.png"}
                                     className="register_logo" alt="Logo" />
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center form_container mt-5">
                            <form onSubmit={this.onSubmit}>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height': '100%'}}><i
                                            className="fas fa-user-circle"/></span>
                                    </div>
                                    <input
                                        type="text"
                                        className={classnames("form-control input_user", {
                                            "is-invalid": errors.name
                                        })}
                                        placeholder="Adın"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChange}
                                    />
                                    {errors.name && (
                                        <div
                                            className="d-flex justify-content-center full-width bg-white rounded-2">
                                            <span style={{'color': '#dc3545'}}>{errors.name}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height': '100%'}}><i
                                            className="far fa-user-circle"/></span>
                                    </div>
                                    <input
                                        type="text"
                                        className={classnames("form-control input_user", {
                                            "is-invalid": errors.surname
                                        })}
                                        placeholder="Soyadın"
                                        name="surname"
                                        value={this.state.surname}
                                        onChange={this.onChange}
                                    />
                                    {errors.surname && (
                                        <div
                                            className="d-flex justify-content-center full-width bg-white rounded-2">
                                            <span style={{'color': '#dc3545'}}>{errors.surname}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height': '100%'}}><i
                                            className="fas fa-map-marked-alt"/></span>
                                    </div>
                                    <Select placeholder="Yaşadığın Şehir"
                                            className="form-control p-0 m-0 border-0"
                                            value={this.state.city}
                                            options={this.state.cities}
                                            onChange={this.onSelectChange}/>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height': '100%'}}><i
                                            className="fas fa-envelope-square"/></span>
                                    </div>
                                    <input
                                        type="text"
                                        className={classnames("form-control input_user", {
                                            "is-invalid": errors.email
                                        })}
                                        placeholder="E-Posta Adresi"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                    />
                                    {errors.email && (
                                        <div
                                            className="d-flex justify-content-center full-width bg-white rounded-2">
                                            <span style={{'color': '#dc3545'}}>{errors.email}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height': '100%'}}><i
                                            className="fas fa-phone"/></span>
                                    </div>
                                    <InputMask
                                        type="text"
                                        mask="0599 999 9999"
                                        className={classnames("form-control input_user", {
                                            "is-invalid": errors.phone
                                        })}
                                        placeholder="Cep Telefonu"
                                        name="phone"
                                        value={this.state.phone}
                                        onChange={this.onChange}
                                    />
                                    {errors.phone && (
                                        <div
                                            className="d-flex justify-content-center full-width bg-white rounded-2">
                                            <span style={{'color': '#dc3545'}}>{errors.phone}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height': '100%'}}><i
                                            className="fas fa-key"/></span>
                                    </div>
                                    <input
                                        type="password"
                                        className={classnames("form-control input_user", {
                                            "is-invalid": errors.password
                                        })}
                                        placeholder="Şifre"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.onChange}
                                    />
                                    {errors.password && (
                                        <div
                                            className="d-flex justify-content-center full-width bg-white rounded-2">
                                            <span style={{'color': '#dc3545'}}>{errors.password}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height': '100%'}}><i
                                            className="fas fa-key"/></span>
                                    </div>
                                    <input
                                        type="password"
                                        className={classnames("form-control input_user", {
                                            "is-invalid": errors.confirmPassword
                                        })}
                                        placeholder="Şifre Tekrar"
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        onChange={this.onChange}
                                    />
                                    {errors.confirmPassword && (
                                        <div
                                            className="d-flex justify-content-center full-width bg-white rounded-2">
                                            <span style={{'color': '#dc3545'}}>{errors.confirmPassword}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <input type="radio"
                                                           className="customRadio"
                                                           name="gender"
                                                           value="MALE"
                                                           onChange={this.onChange}/>
                                                </div>
                                            </div>
                                            <label className="gender_label bg-white my-auto py-1 px-3 rounded-end">Erkek</label>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <input type="radio"
                                                           className="customRadio"
                                                           name="gender"
                                                           value="FEMALE"
                                                           onChange={this.onChange}/>
                                                </div>
                                            </div>
                                            <label className="gender_label bg-white my-auto py-1 px-3 rounded-end">Kadın</label>
                                        </div>
                                    </div>
                                </div>
                                {errors.gender && (
                                    <div
                                        className="d-flex justify-content-center full-width bg-white rounded-2">
                                        <span style={{'color': '#dc3545'}}>{errors.gender}</span>
                                    </div>
                                )}
                                <div className="d-flex justify-content-between mt-3">
                                    <span className={"color-white"}>
                                        Kayıt Ol butonuna tıklayarak, <a className={"color-white"}
                                           href={"/userGuide"}><strong>Kullanım Koşullarımız</strong></a>
                                        'ı ve <a className={"color-white"} href={"/privacyPolicy"}><strong>
                                        Gizlilik Politikamız</strong></a>'ı kabul etmiş olursunuz.
                                    </span>
                                </div>
                                <div className="d-flex justify-content-center mt-3 login_container">
                                    <button type="submit" name="button" className="btn login_btn"
                                            disabled={this.state.isSubmitDisabled}>Kayıt Ol
                                    </button>
                                </div>
                            </form>
                        </div>

                        {errors.errorMessage && (
                            <span>{errors.errorMessage}</span>
                        )}

                        <div className="mt-3">
                            <div className="d-flex justify-content-center links mb-2 link_container">
                                <a href="/forgottenPassword"><strong>Şifremi Unuttum</strong></a>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <a href="/login"><strong>Giriş Yap</strong></a>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <a href="/"><strong>Ana Sayfa</strong></a>
                            </div>
                            <DownloadAppLink/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default RegisterMobile;