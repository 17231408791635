import React from "react";
import Security from "../../security/Security";
import ProfilePicMobile from "../common/ProfilePicMobile";
import UserFullNameMobile from "../common/UserFullNameMobile";
import Globals from "../../util/Globals";

const axios = require('axios');


class ConversationsMobile extends React.Component {
    constructor(props) {
        super(props);
        Security.protect();

        this.state = {
            conversations: [],
            errors: {},
            pageNum: 0,
            noMoreRecords: false
        };

        let self = this;
        this.fillPage();
        this.loadMore = this.loadMore.bind(this);
        window.onscroll = function (ev) {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                if (!self.state.noMoreRecords)
                    self.loadMore();
            }
        };
    }


    loadMore() {
        const self = this;
        let newPageNum = this.state.pageNum + 1;
        this.setState({pageNum: newPageNum});
        axios.get(Globals.serviceUrl + 'message/conversations' + "/" + newPageNum, Security.authHeader())
            .then(function (response) {

                if (response.data.length === 0) {
                    self.setState({noMoreRecords: true});
                    return;
                }

                let newConversations = self.state.conversations;
                newConversations = newConversations.concat(response.data);
                self.setState({conversations: newConversations});
            });
    }

    fillPage() {

        let self = this;

        axios.get(Globals.serviceUrl + 'message/conversations/0', Security.authHeader())
            .then(function (response) {
                self.setState({conversations: response.data});
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });

        axios.get(Globals.serviceUrl + 'notification/readMessages', Security.authHeader());

    }

    readedClass(count) {
        if (count > 0)
            return "newMsg";
        else
            return "readedMsg"
    }

    render() {
        const self = this;
        return (
            <div className="container-fluid" style={{'minHeight': '93vh'}}>

                <br/>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 mb-2 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            KİŞİSEL MESAJLAR
                        </a>
                        <a href={"conversationsActivity"}>
                            <button className={"btn btn-primary"} type="button">
                                <i className="fas fa-users"/>
                            </button>
                        </a>
                    </div>
                </nav>
                {
                    this.state.conversations.map(function (conversation) {
                        return (
                            <div
                                className={"card shadow rounded-2 my-2 text-align-left conversationContainer " + self.readedClass(conversation.unreadCount)}>
                                <div className="d-flex flex-row mb-1">
                                    <div className="ms-1 my-auto">
                                        <ProfilePicMobile
                                            cssClass={"profilePicSmallMobile"}
                                            userId={conversation.profileDto.id}
                                            profilePicName={conversation.profileDto.profilePicName}
                                        />
                                    </div>
                                    <div className="m-1">
                                        <UserFullNameMobile
                                            user={conversation.profileDto}
                                        />
                                        <a href={"/message/" + conversation.profileDto.id}
                                           className={"float-left conversationTextMobile"}>
                                            <div className={""}>
                                                {conversation.lastMessage.substring(0, 100) + "..."}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {this.state.noMoreRecords && (<button className={"btn btn-primary"} onClick={this.loadMore}>Daha
                        fazla göster...
                    </button>
                )}

                <br/>
                <br/>
                <br/>
            </div>
        );
    }
}


export default ConversationsMobile;
