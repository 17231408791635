import React from "react";
import classnames from "classnames";
import Globals from "../../../util/Globals";
import InputMask from "react-input-mask";
import Validator from "../../../util/Validator";

const axios = require('axios');


class ForgottenPasswordMobile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: null
        };
        this.sendPass = this.sendPass.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    sendPass() {
        // post => Login Request
        let phoneValidationResult = Validator.validatePhoneNumber(this.state.phone);
        if (!phoneValidationResult.valid) {
            alert("Telefon numarası uygun formatta değil");
            return;
        }
        this.setState({
                phone: phoneValidationResult.phoneNumer
            });
        axios.get(Globals.serviceUrl + "user/forgottenPassword/" + phoneValidationResult.phoneNumer)
            .then(function (res) {
                alert("Birazdan yeni şifre telefonuna gelecek");
                window.location = "/login";
            })
            .catch(function (e) {
                alert("Bu numaraya kayıtlı bir kullanıcı bulunamadı");
            });
    };

    onChange(e) {
        this.setState({"phone": e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({isSubmitDisabled: true});
        this.sendPass(this.state.mail);
    }

    render() {
        return (
            <div className="container-fluid" style={{'backgroundColor': '#ffe5d0', 'height': '100vh'}}>
                <div className="d-flex justify-content-center" style={{'height': '100vh'}}>
                    <div className="user_card">
                        <div className="d-flex justify-content-center">
                            <div className="brand_logo_container">
                                <img src={"/img/ioslogo3.png"}
                                     className="brand_logo" alt="Logo"/>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center form_container">
                            <h3 className="mb-4 color-white"><strong>ŞİFREMİ UNUTTUM</strong></h3>
                            <form onSubmit={this.onSubmit}>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height': '100%'}}><i
                                            className="fas fa-phone"/></span>
                                    </div>
                                    <InputMask
                                        type="text"
                                        mask="0599 999 9999"
                                        className={classnames("form-control input_user")}
                                        placeholder="Cep Telefonu"
                                        name="phone"
                                        value={this.state.phone}
                                        onChange={this.onChange}
                                        required
                                    />
                                </div>
                                <div className="d-flex justify-content-center mt-3 login_container">
                                    <button type="submit" name="button" className="btn login_btn">Sıfırla
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="mt-3">
                            <div className="d-flex justify-content-center links mb-2">
                                <a href="/login"><strong>Giriş Yap</strong></a>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <a href="/register"><strong>Kayıt Ol</strong></a>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <a href="/"><strong>Ana Sayfa</strong></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgottenPasswordMobile;