import React from "react";
import Security from "../../../security/Security";
import ActivityDetailInputMobil from "../../common/ActivityDetailInputMobil";
import Datetime from "react-datetime";
import SinglePhotoSelectorMobile from "../../common/SinglePhotoSelectorMobile";
import Select from 'react-select'
import Alert from "../../../pcComponents/common/Alert";

const moment = require("moment");

const axios = require('axios');


require('moment/locale/tr');


class BaseActivityFormMobile extends React.Component {
    constructor(props) {
        super(props);
        Security.protect();

        this.state = {
            id: null,
            detail: "",
            photoName: null,
            deadLine: {},
            savedMessage: false,
            selectedFile: null,
            hashtagListString: "",
            isFileSelected: false,
            isSubmitDisabled: false,
            audiance: "everyone",
            cities: [],
            city: {},
            errors: {},
            hideCategories: true,
            categoriesTitle: "Kategorileri Göster",
            allCategories: [],
            selectedCategoryIds: [],
            activityLimitExceeded: false
        };

        this.toggleCategory = this.toggleCategory.bind(this);

        this.loadCategories = this.loadCategories.bind(this);
        this.onChange = this.onChange.bind(this);
        this.dateSelected = this.dateSelected.bind(this);
        this.onHourChange = this.onHourChange.bind(this);
        this.onMinuteChange = this.onMinuteChange.bind(this);
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.loadCities = this.loadCities.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.toggleCategoryVisibility = this.toggleCategoryVisibility.bind(this);

        this.setHours();
        this.setMinutes();
        const now = moment();
        this.state.deadLine = {
            date: now.format("DD/MM/YYYY"),
            hour: {label: "18", value: "18"},
            minute: {label: "00", value: "00"}
        };

    }


    toggleCategory(id) {
        let alreadyExists = this.state.selectedCategoryIds.includes(id);
        let selectedCategoryIds = this.state.selectedCategoryIds;

        if (alreadyExists) {
            const index = this.state.selectedCategoryIds.indexOf(id);
            if (index > -1) {
                selectedCategoryIds.splice(index, 1);
            }
        } else {
            selectedCategoryIds.push(id);
        }

        this.setState({selectedCategoryIds: selectedCategoryIds});
    }


    setHours() {
        let arr = [];
        let hour;
        for (let i = 23; i >= 0; i--) {

            if (i < 10) {
                hour = {value: "0" + i.toString(), label: "0" + i.toString()}
            } else {
                hour = {value: i.toString(), label: i.toString()};
            }
            arr.push(hour);
        }
        this.state.hours = arr;
    }

    setMinutes() {
        let arr = [];
        let minute;
        for (let i = 0; i < 4; i++) {
            if (i == 0)
                minute = {label: "00", value: "00"};
            else
                minute = {label: (i * 15).toString(), value: (i * 15).toString()};


            arr.push(minute);
        }

        this.state.minutes = arr;
    }


    dateSelected(e) {
        let selectedDate = e.format("DD/MM/YYYY");
        let deadLineNew = Object.assign({}, this.state.deadLine);
        deadLineNew.date = selectedDate;
        this.setState({deadLine: deadLineNew});
    }

    toggleCategoryVisibility() {
        let not = !this.state.hideCategories;
        this.setState({hideCategories: not});

        if (not)
            this.setState({categoriesTitle: "Kategorileri Göster"});
        if (!not)
            this.setState({categoriesTitle: "Kategorileri Gizle"});

    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSelectChange(e) {
        console.log(e);
        this.setState({city: e});
    }

    onHourChange(e) {
        let deadLineNew = Object.assign({}, this.state.deadLine);
        deadLineNew.hour = e;
        this.setState({deadLine: deadLineNew});
    }

    onMinuteChange(e) {
        let deadLineNew = Object.assign({}, this.state.deadLine);
        deadLineNew.minute = e;
        this.setState({deadLine: deadLineNew});
    }

    handleSelectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
            isFileSelected: "Fotoğraf Yüklenmeye Hazır"
        })
    };

    isValidDate(currentDate, selectedDate) {
        if (moment().subtract(1, "days") < currentDate)
            return true;

        return false;
    }

    render() {

        let self = this;
        const {savedMessage} = this.state;
        const {errors} = this.state;
        const {photoName} = this.state;
        const {hours} = this.state;
        const {minutes} = this.state;

        if (localStorage.getItem("cityId") === "null") {
            return (<div className="full-width container">
                <a href="/updateInfo">
                    <div className={"alert alert-danger"}>
                        Akışı görebilmek için buraya tıklayıp ŞEHİR ve TELEFON bilgisi girmelisin
                    </div>
                </a>
            </div>)
        }

        if (this.state.activityLimitExceeded) {
            return (
                <div className={"full-width container"}><Alert
                    type={"alert-warning"}
                    message={this.state.activityLimitExceeded}
                /></div>
            )
        }


        return (
            <div className="full-width container-fluid" style={{'minHeight': '93vh'}}>

                <br/>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            YENİ AKTİVİTE
                        </a>
                    </div>
                </nav>

                <div className="card mt-2 mb-4">
                    <div className="card-body">
                        <div className="alert-info shadow rounded-3">Bugünlerde ne planlıyorsan ya da her ne yapmak
                            istiyorsan bizimle paylaş, gel birlikte yapalım!
                        </div>

                        {photoName && (
                            <img className={"meetingFormPhoto"} src={"/upload/" + photoName}/>
                        )}

                        <form onSubmit={this.onSubmit}>

                            <div className="row my-3">
                                <div className="col-4 my-auto text-align-left">
                                    <span><i className="fas fa-map-marker-alt"/> Şehir</span>
                                </div>
                                <div className="col-8">
                                    <Select value={this.state.city} options={this.state.cities}
                                            onChange={this.onSelectChange}/>
                                </div>
                            </div>

                            <ActivityDetailInputMobil
                                error={errors.detail}
                                onChange={this.onChange}
                                detail={this.state.detail}
                                hashtagListString={this.state.hashtagListString}
                            />

                            <div className="row my-3">
                                <div className="col-4 my-auto text-align-left">
                                    <span><i className="fas fa-filter"/> Kategori</span>
                                </div>
                                <div className="col-8">
                                    <button type={"button"} className={"btn btn-secondary full-width"}
                                            onClick={this.toggleCategoryVisibility}><i
                                        className="fas fa-plus-circle"/> {this.state.categoriesTitle}</button>
                                </div>
                                {errors.selectedCategoryIds && (
                                    <div className="col-12 alert-danger my-3 shadow rounded-3">
                                        <span className={"error-message"}>{errors.selectedCategoryIds}</span>
                                    </div>
                                )}
                            </div>

                            <div hidden={this.state.hideCategories}>
                                <div className="row my-3">
                                    {this.state.allCategories.length > 0 && this.state.allCategories.map(function (cat) {

                                        let catClass = "category-button-passive";
                                        if (self.state.selectedCategoryIds.includes(cat.id))
                                            catClass = "category-button-active";
                                        return (
                                            <div className={"half-left category-button orange-shadow " + catClass}
                                                 onClick={() => self.toggleCategory(cat.id)}>
                                                <span style={{fontSize: "13px"}}> {cat.name} ({cat.watcherCount})</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col-4 my-auto text-align-left">
                                    <span><i className="far fa-calendar-alt"/> Tarih</span>
                                </div>
                                <div className="col-8">
                                    <div className={"text-align-center"}>
                                        <Datetime
                                            className={"full-calendar"}
                                            dateFormat={"DD/MM/YYYY"}
                                            timeFormat={false}
                                            onChange={this.dateSelected}
                                            isValidDate={this.isValidDate}
                                            locale={"tr"}
                                            open={true}
                                            input={false}
                                            value={this.state.deadLine.date}
                                        />
                                    </div>
                                </div>
                                <div className="col-4 my-auto text-align-left">
                                    <span><i
                                        className="far fa-clock"/> Saat</span>
                                </div>
                                <div className="col-8 mt-2 row">
                                    <div className="col-6 m-auto">
                                        <Select isSearchable={false} ref={node => this.secondSelect = node}
                                                options={hours} value={this.state.deadLine.hour}
                                                onChange={this.onHourChange}/>
                                    </div>
                                    <div className="col-6 m-auto">
                                        <Select isSearchable={false} options={minutes}
                                                value={this.state.deadLine.minute}
                                                onChange={this.onMinuteChange}/>
                                    </div>
                                </div>

                                {errors.deadLineString && (
                                    <div className="col-12 alert-danger shadow rounded-3">
                                        <span>{errors.deadLineString}</span>
                                    </div>
                                )}
                            </div>

                            <div className="row my-3">
                                <div className="col-4 my-auto text-align-left">
                                    <span><i className="fas fa-image"/> Fotoğraf</span>
                                </div>
                                <div className="col-8">
                                    <SinglePhotoSelectorMobile
                                        isFileSelected={this.state.isFileSelected}
                                        onChange={this.handleSelectedFile}
                                        error={errors.file}
                                    />
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col-12 my-auto text-align-left">
                                    <span>Bu aktiviteyi kimler görebilir?</span>
                                </div>
                                <div className="col-12 mt-2 row">
                                    <div className="col-6">
                                        <input type="radio"
                                               name="audiance"
                                               value="mylist"
                                               onChange={this.onChange}
                                               className="customRadio"
                                               checked={this.state.audiance == "mylist"}
                                        />
                                        <label className="customRadioLabelMobile ">&nbsp;Yalnız listem&nbsp;</label>
                                    </div>
                                    <div className="col-6">
                                        <input type="radio"
                                               name="audiance"
                                               onChange={this.onChange}
                                               value="everyone"
                                               checked={this.state.audiance == "everyone"}
                                               className="customRadio"
                                        />
                                        <label className="customRadioLabelMobile ">&nbsp;Herkes&nbsp;</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {errors.gender}
                                </div>
                            </div>

                            {savedMessage && (
                                <div className={"alert alert-success"}>
                                    {savedMessage}
                                    <a href="/"><strong>Aktiviten Yayında! burada görünüyor :)</strong> </a>
                                </div>
                            )}
                            {errors.userWarningMessage && (
                                <div className={"alert alert-danger"}>
                                    {savedMessage}
                                    <a href="/"><strong>{errors.userWarningMessage}</strong> </a>
                                </div>
                            )}
                            <input
                                type="submit"
                                value="Kaydet ve Yayınla"
                                className="btn btn-primary btn-block mt-2 full-width"
                                disabled={this.state.isSubmitDisabled}
                            />
                        </form>
                    </div>
                </div>
                <br/>
                <br/>
            </div>
        );
    }
}


export default BaseActivityFormMobile;
