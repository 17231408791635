import React from "react";
import Security from "../../security/Security";
import classnames from "classnames";
import ProfilePicMobile from "../common/ProfilePicMobile";
import UserFullNameMobile from "../common/UserFullNameMobile";
import MessageBoxMobile from "./DisplayMessagesMobile";
import UserUtil from "../../util/UserUtil";
import Globals from "../../util/Globals";

const axios = require('axios');


class MessagePageMobile extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        Security.protect();
        UserUtil.redirectIsBlocked(this.props.match.params.id);


        this.state = {
            message: "",
            readerProfile: {},
            errors: {},
            filterVisible: false
        };
        this.deleteConvo = this.deleteConvo.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.canItext = this.canItext.bind(this);
        this.fillPage = this.fillPage.bind(this);
        this.toggleButtonVisible = this.toggleButtonVisible.bind(this);
        this.canItext();
        this.fillPage();
    }


    canItext() {
        const self = this;
        axios.get(Globals.serviceUrl + 'message/haveTheseUsersMeet/' + this.props.match.params.id + '/' + localStorage.getItem("userId"), Security.authHeader())
            .then(function (response) {
                self.setState({"canItext": response.data});

            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    deleteConvo() {
        if (window.confirm("Bu konuşmayı silmek istediğinden emin misin?")) {
            axios.get(Globals.serviceUrl + 'message/deleteConversation/' + this.props.match.params.id, Security.authHeader())
                .then(function (response) {
                    window.location = "/conversations";
                });
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const message = {
            message: this.state.message,
            reader: this.state.readerProfile
        };
        //this.sendMessage(message);
        this.child.current.sendMessagee(message);
        this.setState({message: ""});
    }


    sendMessage(messageDto) {
        const self = this;
        axios.post(Globals.serviceUrl + 'message/send/', messageDto, Security.authHeader())
            .then(function (response) {
                // let messages = self.state.messages;
                // let newMessage = response.data;
                // messages.push(newMessage);
                // self.setState({messages: messages});
                self.setState({message: ""});
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });
        this.child.current.fillPage();
    }

    fillPage() {
        let self = this;

        //get the reader user
        axios.get(Globals.serviceUrl + 'user/profile/' + this.props.match.params.id)
            .then(function (response) {
                self.setState({readerProfile: response.data});
            })
            .catch(function (error) {
                console.log(error);
                self.setState({"errors": error.response.data});
            });
    }

    toggleButtonVisible() {
        this.setState(prevState => ({
            filterVisible: !prevState.filterVisible
        }));
    }

    render() {

        return (
            <div className={"container-fluid"} style={{'minHeight': '93vh'}}>
                <nav className="filter-navbar navbar shadow rounded-3 sticky-top messageNavbar">
                    <div className="container-fluid">
                        <div className="justify-content-start">
                            <ProfilePicMobile
                                userId={this.props.match.params.id}
                                profilePicName={this.state.readerProfile.profilePicName}
                                cssClass={"profilePicSmallMobile my-1"}/>
                            &nbsp;
                            <UserFullNameMobile user={this.state.readerProfile}/>
                        </div>

                        <button className={this.state.filterVisible ? "btn btn-primary" : "btn btn-outline-primary"}
                                type="button" onClick={this.toggleButtonVisible}>
                            <i className="fa fa-ellipsis-v"/>
                        </button>
                        {this.state.filterVisible && (<div className="full-width mt-1">
                            <div className="d-flex flex-row-reverse">
                                <button onClick={() => this.deleteConvo()}
                                        className={"btn btn-danger ms-2"}>Konuşmayı Sil
                                </button>
                                <a className={"complainOnMessagePage"}
                                   href={"/complain/" + this.state.readerProfile.id}>
                                    <button className={"btn btn-success"}> Şikayet Et</button>
                                </a>
                            </div>
                        </div>)}
                    </div>
                </nav>

                <br/>
                <MessageBoxMobile ref={this.child} activityId={this.props.match.params.id}/>

                {this.state.canItext && (
                    <div>
                        <form onSubmit={this.onSubmit}>
                            <div className="input-group mb-3">
                                <textarea
                                    className={classnames("form-control ", {
                                        "is-invalid": this.state.errors.message
                                    })}
                                    placeholder="Bir mesaj yazın..."
                                    name="message"
                                    value={this.state.message}
                                    onChange={this.onChange}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" style={{'height': '100%'}} type="submit">
                                        <i className="far fa-comment-dots"/>
                                    </button>
                                </div>
                            </div>
                        </form>
                        {this.state.errors.message && (
                            <div className="alert alert-danger">
                                {this.state.errors.message}
                            </div>
                        )}
                    </div>
                )}

                {!this.state.canItext && (
                    <div className="alert alert-info rounded-2">
                            <span>Bu kişi ile yakın zamanda onaylanmış bir aktiviten yok, yalnız yakın zamanda ortak aktiviten olan kişilere mesaj atabilirsin. <br/>
                            Sosyalleşmek için bir aktivite oluşturabilir veya birine katılabilirsin :)</span>
                    </div>
                )}
            </div>

        );
    }
}


export default MessagePageMobile;
