import React from "react";


class AboutMobile extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {


        return (
            <div className="full-width text-align-left container" style={{'minHeight': '93vh'}}>
                <h4>Hakkında</h4>
                <hr/>
                Şubat 2019'da ilk kez kullanıma açılan ActiZone ticari olmayan bir sosyal platformdur. Bağımsız
                geliştiriciler tarafından, sıfır sermaye while
                geliştirilip Ankara'da çıkış yapmıştır. İnsanların gerçek hayatta birlikte birşeyler yapmaları,
                tanışmaları, bulundukları şehri yakından tanımaları amacını
                taşımaktadır.<br/><br/>
                Geliştirilmesi devam etmektedir.
            </div>

        )
    }
}

export default AboutMobile;
