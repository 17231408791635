import React from "react";
import UserUtil from "../../../util/UserUtil";
import ProfilePicMobile from "../../common/ProfilePicMobile";
import UserFullNameMobile from "../../common/UserFullNameMobile";
import Globals from "../../../util/Globals";

const axios = require('axios');


class SearchUserMobile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: "",
            userNotFoundMessage: false,
            type: "NAME",
            pageNum: 0,
            noMoreRecords: true,
            users: [],
            errors: {}
        };

        this.loadMore = this.loadMore.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        let self = this;
        window.onscroll = function (ev) {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                self.loadMore();
            }
        };

    }

    loadMore() {
        const self = this;
        let newPageNum = this.state.pageNum + 1;
        this.setState({pageNum: newPageNum});

        let searchText = this.state.searchText;
        searchText = searchText.replace(" ", "");


        if (this.state.type === "NAME")
            axios.get(Globals.serviceUrl + 'user/search/' + searchText + "/" + newPageNum)
                .then(function (response) {
                    if (response.data.length === 0) {
                        self.setState({noMoreRecords: true});
                        return;
                    }
                    let newUsers = self.state.users;
                    newUsers = newUsers.concat(response.data);
                    console.log(newUsers);
                    self.setState({"users": newUsers});
                });


        if (this.state.type === "HASHTAG")
            axios.get(Globals.serviceUrl + 'hashtag/findUsers/' + searchText + "/" + newPageNum)
                .then(function (response) {
                    if (response.data.length === 0) {
                        self.setState({noMoreRecords: true});
                        return;
                    }
                    let newUsers = self.state.users;
                    newUsers = newUsers.concat(response.data);
                    self.setState({"users": newUsers});
                    console.log(this.state.users);
                });

    }


    searchUser(searchTerm) {
        searchTerm = searchTerm.replace(" ", "");

        let self = this;
        if (this.state.type === "NAME")
            axios.get(Globals.serviceUrl + 'user/search/' + searchTerm + "/" + this.state.pageNum)
                .then(function (response) {
                    self.setState({"users": response.data});
                    self.setState({"userNotFoundMessage": false});

                    if (response.data.length === 0) {
                        self.setState({"userNotFoundMessage": "Kullanıcı Bulunamadı"});
                        self.setState({noMoreRecords: true});
                    } else
                        self.setState({noMoreRecords: false});
                })
                .catch(function (error) {
                    console.log(error.response);
                });

        if (this.state.type === "HASHTAG")
            axios.get(Globals.serviceUrl + 'hashtag/findUsers/' + searchTerm + "/" + this.state.pageNum)
                .then(function (response) {
                    self.setState({"userNotFoundMessage": false});
                    self.setState({"users": response.data});

                    if (response.data.length === 0) {
                        self.setState({"userNotFoundMessage": "Kullanıcı Bulunamadı"});
                        self.setState({noMoreRecords: true});
                    } else
                        self.setState({noMoreRecords: false});
                })
                .catch(function (error) {
                    console.log(error.response);
                });

    }

    searchFromUrlQuery(searchText) {
        this.setState({"searchText": searchText});
        this.searchUser(searchText);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        this.searchUser(this.state.searchText);
    }

    componentDidMount() {
        let urlQUery = this.props.location.search;
        let fullname = urlQUery.split("fullname=")[1];
        let hashtag = urlQUery.split("hashtag=")[1];


        if (fullname) {
            fullname = fullname.replace("+", " ");
            this.state.type = "NAME";
            this.searchFromUrlQuery(fullname);
        }
        if (hashtag) {
            this.state.type = "HASHTAG";
            this.searchFromUrlQuery(hashtag);
        }
    }


    render() {

        const {userNotFoundMessage} = this.state;
        const self = this;

        return (
            <div className="container-fluid" style={{'minHeight': '93vh'}}>
                <br/>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            KULLANICI ARA
                        </a>
                    </div>
                </nav>
                <div className="card p-3 shadow rounded-2 text-align-center">
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <div className="row my-2 text-align-left">
                                <div className="col-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <input type="radio"
                                                       className="customRadio"
                                                       name="type"
                                                       value="NAME"
                                                       onChange={this.onChange}
                                                       checked={this.state.type === "NAME"}/>
                                            </div>
                                        </div>
                                        <label
                                            className="search_label bg-white my-auto py-1 px-3 rounded-end">İsim</label>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <input type="radio"
                                                       className="customRadio"
                                                       name="type"
                                                       value="HASHTAG"
                                                       onChange={this.onChange}
                                                       checked={this.state.type === "HASHTAG"}/>
                                            </div>
                                        </div>
                                        <label
                                            className="search_label bg-white my-auto py-1 px-3 rounded-end">Hashtag</label>
                                    </div>
                                </div>
                            </div>
                            <input className="form-control"
                                   type="text"
                                   name="searchText"
                                   value={this.state.searchText}
                                   onChange={this.onChange}
                                   required/>
                            <button type="submit" className="btn btn-primary btn-block my-1 px-5">ARA</button>
                        </div>
                    </form>

                    {userNotFoundMessage && (
                        <div className="alert alert-info mt-3 mb-0">
                            <h4>{userNotFoundMessage}</h4>
                        </div>
                    )}

                    {self.state.users.map((user, i) => {

                        return (<div className="card shadow rounded-2 my-1 searchItemContainer">
                            <div className="d-flex flex-row text-align-left mb-2">
                                <div className="ms-2 mt-2 my-auto">
                                    <ProfilePicMobile
                                        userId={user.id}
                                        profilePicName={user.profilePicName}
                                        cssClass={"profilePicSmallMobile"}
                                    />
                                    <br/>

                                </div>
                                <div className="mx-2 my-auto">
                                    <UserFullNameMobile
                                        user={user}
                                    />
                                    <br/>
                                    <span>{UserUtil.translateGender(user.gender)} / {user.age}  </span>
                                </div>
                            </div>
                        </div>)
                    })}
                    <button hidden={this.state.noMoreRecords} className={"btn btn-secondary"}
                            onClick={this.loadMore}>
                        Daha fazla göster...
                    </button>

                </div>
            </div>
        )
    }
}

export default SearchUserMobile;
