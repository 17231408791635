import React from "react";
import Security from "../../../security/Security";
import ProfilePicMobile from "../../common/ProfilePicMobile";
import UserFullNameMobile from "../../common/UserFullNameMobile";
import JSUtil from "../../../util/JSUtil";
import Globals from "../../../util/Globals";

const axios = require('axios');


class FollowingsMobile extends React.Component {
    constructor() {
        super();
        Security.protect()

        this.state = {
            followings: [],
            erorrs: {}
        };

        this.fillPage();
    }

    fillPage() {
        const self = this;
        axios.get(Globals.serviceUrl+'follow/myFollowings', Security.authHeader())
            .then(function (response) {
                self.setState({followings: response.data});
            })
            .catch(function (error) {
                console.log(error.response);
            });

    }

    unfollow(id, name) {
        const self = this;

        if (window.confirm("Artık " + name + " ile ilgili bildirim almayacaksınız, emin misiniz?"))
            axios.get(Globals.serviceUrl+'follow/follow/' + id, Security.authHeader())
                .then(function (response) {

                    let followings = self.state.followings;
                    JSUtil.deleteFromArrayByPropertyName(followings, "id", id);
                    self.setState({blocks: followings});
                })
                .catch(function (error) {
                });
    }


    render() {
        const self = this;
        return (
            <div className="full-width container" style={{'minHeight': '93vh'}}>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            TAKİP ETTİKLERİM
                        </a>
                    </div>
                </nav>
                <div>{self.state.followings.length===0 && (
                    <div className="col-12 alert alert-info mt-3 mb-0">
                       <span>
                         Takip ettiğin kimse yok!
                    </span>
                    </div>)}

                </div>

                {
                    self.state.followings.map(function (following, i) {
                        return (
                            <div  className="card shadow rounded-2 my-1 searchItemContainer">
                                <div className="d-flex flex-row  mb-2">
                                    <div className="col-6 ms-2 mt-2 my-auto">
                                    <ProfilePicMobile
                                        userId={following.id}
                                        profilePicName={following.profilePicName}
                                        cssClass={"profilePicSmallMobile"}
                                    />
                                    <br/>
                                    <UserFullNameMobile
                                        user={following}
                                    />
                                </div>
                                <div className="col-5 mx-2 my-auto"><br/>
                                    <button onClick={() => self.unfollow(following.id, following.name)}
                                            className={"btn btn-danger"}>Takibi bırak
                                    </button>
                                </div>
                                <hr/>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        )
    }
}

export default FollowingsMobile;
