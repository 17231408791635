import React, {Component} from "react";
import classnames from "classnames";
import security from "../../../security/Security";
import AlertMobile from "../../common/AlertMobile";
import Globals from "../../../util/Globals";
import DownloadAppLink from "../../common/DownloadAppLink";
import Security from "../../../security/Security";

const axios = require('axios');

class LoginMobile extends Component {
    constructor() {
        super();

        if (localStorage.getItem("jwtToken")) {
            security.logout();
        }
        this.state = {
            username: "",
            password: "",
            isSubmitDisabled: false,
            errors: {},
            firebaseToken : localStorage.getItem("firebaseToken")
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    login(LoginRequest) {
        const self = this;
        try {
            // post => LoginMobile Request
            axios.post(Globals.serviceUrl + "user/login", LoginRequest)
                .then(function (res) {

                alert("Yeni uygulamamız App store ve Play Store'da actizone ismiyle yayınlanmıştır. Lütfen yeni uygulamamızı yükleyin.");
                    const {token} = res.data;
                    const {profilePicName} = res.data;
                    const {cityId} = res.data;
                    security.setLoginCredentials(token, profilePicName, cityId);
                    sessionStorage.removeItem("sDate");
                    sessionStorage.removeItem("eDate");
                    sessionStorage.removeItem("pageNum") ;
                    sessionStorage.removeItem("scroll");

                    if(self.state.firebaseToken) {
                        axios.get(Globals.serviceUrl + 'm/aios/' + self.state.firebaseToken, Security.authHeader())
                            .then(function (response) {
                                console.log(response.data);
                            })
                            .catch(function (error) {
                                console.log(error.response.data);
                            });
                    }

                    axios.get(Globals.serviceUrl + 'm/ok', Security.authHeader())
                        .then(function (response) {
                            console.log(response.data);
                        })
                        .catch(function (error) {
                    });

                    window.location = "/allActivities";
                }).catch(function (error) {
                console.log(error);
                console.log(error.response.data);
                self.setState({isSubmitDisabled: false});
                self.setState({"errors": error.response.data});
            });
        } catch (err) {

        }
    };


    onSubmit(e) {
        e.preventDefault();
        this.setState({isSubmitDisabled: true});
        const LoginRequest = {
            username: this.state.username,
            password: this.state.password
        };

        this.login(LoginRequest);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        const {errors} = this.state;
        return (
            <div className="container-fluid" style={{'backgroundColor':'#ffe5d0','height':'100vh'}}>
                <div className="d-flex justify-content-center" style={{'height':'100vh'}}>
                    <div className="user_card">
                        <div className="d-flex justify-content-center">
                            <div className="brand_logo_container">
                                <img src={"/img/ioslogo3.png"}
                                    className="brand_logo" alt="Logo" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center form_container">
                            <form onSubmit={this.onSubmit}>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height':'100%'}}><i className="fas fa-user" /></span>
                                    </div>
                                    <input type="text"
                                           name="username"
                                           className={classnames("form-control input_user", {
                                               "is-invalid": errors.userWarningMessage
                                           })}
                                           value={this.state.username}
                                           onChange={this.onChange}
                                           placeholder="E-Posta Adresi" />
                                </div>
                                <div className="input-group mb-2">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height':'100%'}}><i className="fa fa-key" /></span>
                                    </div>
                                    <input type="password"
                                           name="password"
                                           className={classnames("form-control input_pass", {
                                               "is-invalid": errors.userWarningMessage
                                           })}
                                           value={this.state.password}
                                           onChange={this.onChange}
                                           placeholder="Şifre" />
                                </div>
                                {errors.userWarningMessage && (
                                    <div className="d-flex justify-content-center login_container bg-white rounded-2">
                                        <span style={{'color':'#dc3545'}}>{errors.userWarningMessage}</span>
                                    </div>
                                )}
                                <div className="d-flex justify-content-center mt-3 login_container">
                                    <button type="submit" name="button" className="btn login_btn">Giriş Yap</button>
                                </div>
                            </form>
                        </div>

                        {errors.errorMessage && (
                            <span>{errors.errorMessage}</span>
                        )}

                        <div className="mt-3">
                            <div className="d-flex justify-content-center mb-2 link_container">
                                <a href="/forgottenPassword"><strong>Şifremi Unuttum</strong></a>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <a href="/register"><strong>Kayıt Ol</strong></a>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <a href="/"><strong>Ana Sayfa</strong></a>
                            </div>
                            <DownloadAppLink/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginMobile;
