import React from "react";
import Security from "../../../security/Security";
import UserUtil from "../../../util/UserUtil";
import Globals from "../../../util/Globals";
import CompleteProfile from "../../../pcComponents/common/CompleteProfile";
import StarRatings from 'react-star-ratings';

const axios = require('axios');

class MyProfileMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            surname: "",
            gender: " ",
            profilePicName: "",
            about: "",
            age: "",
            attendPercent: null,
            motivation: "",
            interestsArray: [],
            activityCount: 0,
            premiumType: false,
            errors: {},
            latestPremiumDate: "",
            vibePercent: 0,
            vibeCount: 0,
            followerCount: 0,
            vibePercentStart: 0,
            vibePercentEnd: 0,
        };

        this.deleteAccount = this.deleteAccount.bind(this);

    }

    componentDidMount() {
        this.fillPage();
    }

    deleteAccount() {
        let res = window.prompt("Hesabın tamamen silinecek, gerçekten silmek istiyorsan aşağıdaki kutuya onaylıyorum yaz ve onay butonunu tıkla");
        let self = this;

        if (res === "onaylıyorum") {
            axios.get(Globals.serviceUrl + 'user/deleteAccount/' + localStorage.getItem("userId"), Security.authHeader()).then(function (response) {
                alert("Hesabın silindi :( umarım geri gelirsin :(");
                localStorage.removeItem("jwtToken");
                window.location = "/";
            })
                .catch(function (error) {
                    console.log(error);
                    self.setState({"errors": error.response.data});
                });
        }
    }


    fillPage() {
        let self = this;
        let userId = localStorage.getItem("userId");

        axios.get(Globals.serviceUrl + 'user/profile/' + userId)
            .then(function (response) {
                self.setState(response.data);
                // self.setState({"name": UserUtil.translateGender(response.data.name)});
                // self.setState({"surname": UserUtil.translateGender(response.data.surname)});
                self.setState({"gender": UserUtil.translateGender(self.state.gender)});
                self.setState({"profilePicName": response.data.profilePicName});

                self.setState({interestsArray: response.data.categories});
            });

        axios.get(Globals.serviceUrl + 'premium/latestPremiumDate/', Security.authHeader())
            .then(function (response) {
                self.setState({latestPremiumDate: response.data});
            });
        axios.get(Globals.serviceUrl + 'vibe/vibeCountOfUser/' + userId, Security.authHeader())
            .then(function (response) {
                self.setState({vibeCount: response.data});
            });

        axios.get(Globals.serviceUrl + 'vibe/vibePercent/' + userId, Security.authHeader())
            .then(function (response) {
                if (response.data >= 4.5 && response.data <= 5) {
                    self.setState({"vibePercentStart": 4.5});
                    self.setState({"vibePercentEnd": 5.0});
                } else if (response.data >= 4.0 && response.data < 4.5) {
                    self.setState({"vibePercentStart": 4.0});
                    self.setState({"vibePercentEnd": 4.5});
                } else if (response.data >= 3.5 && response.data < 4.0) {
                    self.setState({"vibePercentStart": 3.5});
                    self.setState({"vibePercentEnd": 4.0});
                } else if (response.data >= 3.0 && response.data < 3.5) {
                    self.setState({"vibePercentStart": 3.0});
                    self.setState({"vibePercentEnd": 3.5});
                } else if (response.data >= 2.5 && response.data < 3.0) {
                    self.setState({"vibePercentStart": 2.5});
                    self.setState({"vibePercentEnd": 3.0});
                } else if (response.data >= 2.0 && response.data < 2.5) {
                    self.setState({"vibePercentStart": 2.0});
                    self.setState({"vibePercentEnd": 2.5});
                } else if (response.data >= 1.5 && response.data < 2.0) {
                    self.setState({"vibePercentStart": 1.5});
                    self.setState({"vibePercentEnd": 2.0});
                } else if (response.data >= 1.0 && response.data < 1.5) {
                    self.setState({"vibePercentStart": 1.0});
                    self.setState({"vibePercentEnd": 1.5});
                } else if (response.data >= 0.5 && response.data < 1.0) {
                    self.setState({"vibePercentStart": 0.5});
                    self.setState({"vibePercentEnd": 1});
                } else if (response.data > 0.0 && response.data < 0.5) {
                    self.setState({"vibePercentStart": 0.0});
                    self.setState({"vibePercentEnd": 0.5});
                }

                self.setState({"vibePercent": response.data});
            });
    }

    render() {

        let {interestsArray} = this.state;

        return (
            <div className="full-width container" style={{'minHeight': '93vh'}}>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            PROFİLİM
                        </a>
                        <a href="/updateInfo/">
                            <button className="btn btn-primary">
                                <i className="fas fa-pencil-alt"/>
                            </button>
                        </a>
                    </div>
                </nav>
                <div className="container-fluid card shadow my-1 rounded-2">
                    <div className="row mb-2">
                        <div className="col-3 my-3">
                            <a href={"/updateProfilePic/"}>
                                <img className={"profilePicMedium"}
                                     src={UserUtil.buildProfilePicUrl(this.state.profilePicName)}/>
                            </a>
                        </div>
                        <div className="col-9 m-auto text-end">
                            <a className="userFullName ms-2" href={"/profile/" + this.props.match.params.id}>
                                <strong>
                                    {/*{this.state.premiumType === "GOLD" && (*/}

                                    {/*    <span className={'goldCheck'}><i className="far fa-check-circle"/>&nbsp;</span>*/}
                                    {/*)}*/}
                                    {/*{this.state.premiumType === "SILVER" && (*/}

                                    {/*    <span className={'silverCheck'}><i className="far fa-check-circle"/>&nbsp;</span>*/}
                                    {/*)}*/}
                                    {/*{this.state.premiumType === "ORGANIZATOR" && (*/}

                                    {/*    <span className={'proCheck'}><i className="fas fa-certificate"/>&nbsp;</span>*/}
                                    {/*)}*/}
                                    {this.state.name + " " + this.state.surname}</strong>
                            </a>
                            <br/>
                            <strong>{this.state.gender} / {this.state.age} / </strong>
                            <a href={"/myFollowers"} style={{'textDecoration': 'none', 'color': '#fd7e14'}}> <strong><i
                                className="fas fa-user"/> {this.state.followerCount}
                            </strong></a>
                            <div className="mt-2">
                                {this.state.vibePercentEnd > 0 && (<StarRatings
                                    rating={this.state.vibePercent}
                                    starRatedColor="#198754"
                                    starDimension="24px"
                                    starSpacing="1px"
                                    numberOfStars={5}
                                />)}
                                <strong>({this.state.vibeCount} oy)
                                    {this.state.vibePercent === 0 && (
                                        <span>Yeterli veri yok!</span>
                                    )}
                                </strong>
                            </div>
                        </div>

                        {this.state.attendPercent != null && (
                            <div className="col-12">
                                <div>
                                    Onaylandığı aktivitelere katılım oranı:
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar"
                                             style={{width: this.state.attendPercent + '%'}} aria-valuenow="50"
                                             aria-valuemin="0" aria-valuemax="100">{this.state.attendPercent}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/*  {this.state.latestPremiumDate !== "" && (
                            <div className="col-12 mt-2">
                                <span>Premium üyelik son tarih: {this.state.latestPremiumDate}</span>
                            </div>
                        )}*/}
                    </div>

                    <div className="row">
                        <div className="col-6 px-0">
                            <a href="/myAlbum/">
                                <button className={"btn btn-menuColorMobile orange-shadow profileButton"}><i
                                    className="fas fa-images"/> Albüm
                                </button>
                            </a>
                        </div>
                        <div className="col-6 px-0">
                            <a href="/followings/">
                                <button className={"btn btn-menuColorMobile orange-shadow profileButton"}><i
                                    className="fas fa-bell"/> Takip Listesi
                                </button>
                            </a>
                        </div>
                        {/*<div className="col-6 px-0">
                            <a href="/referenceCodes/">
                                <button className={"btn btn-menuColorMobile orange-shadow profileButton"}><i
                                    className="fas fa-check"/> Referanslar
                                </button>
                            </a>
                        </div>*/}
                    </div>
                    <div className="row">
                        <div className="col-6 px-0">
                            <a href="/categories/">
                                <button className={"btn btn-menuColorMobile orange-shadow profileButton"}><i
                                    className="fas fa-chess-knight"/> İlgi Alanlarım
                                </button>
                            </a>
                        </div>
                        <div className="col-6 px-0">
                            <a href="/blocks/">
                                <button className={"btn btn-menuColorMobile orange-shadow profileButton"}><i
                                    className="fas fa-ban"/> Engel Listesi
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 px-0">
                            <a href={"/usersICanVibe"}>
                                <button className={"btn btn-menuColorMobile orange-shadow profileButton"}><i
                                    className="fas fa-star"/> Verdiğim
                                    Oylar
                                </button>
                            </a>
                        </div>
                        <div className="col-6 px-0">
                            <a href="/updatePassword/">
                                <button className={"btn btn-menuColorMobile orange-shadow profileButton"}><i
                                    className="fas fa-key"/> Şifre Güncelle
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-4 px-0">
                            <div className="profileTitleMobileDiv p-2 m-1">
                                <a className="profilePhotoReviewActivity"
                                   href={"/album/" + localStorage.getItem("userId")}>
                                    Fotoğraflar
                                </a>
                            </div>
                        </div>
                        <div className="col-4 px-0">
                            <div className="profileTitleMobileDiv p-2 m-1">
                                <a className="profilePhotoReviewActivity"
                                   href={"/reviews/" + localStorage.getItem("userId")}>
                                    Yorumlar
                                </a>
                            </div>
                        </div>
                        <div className="col-4 px-0">
                            <div className="profileTitleMobileDiv p-2 m-1">
                                <a className="profilePhotoReviewActivity"
                                   href={"/userActivities/" + localStorage.getItem("userId")}>
                                    Aktiviteler
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <CompleteProfile
                    userId={this.props.match.params.id}
                    profilePicName={this.state.profilePicName}
                    age={this.state.age}
                    about={this.state.about}
                    interestsArray={this.state.interestsArray}
                    photoCount={this.state.photoCount}
                />
                <br/>
                {(localStorage.getItem("userId") === "3212")
                && (
                    <div className={"full-width"}>
                        <a href={"/uhktybb/police"} className={"float-left"}>
                            <button className={"btn btn-danger"}>Kullanıcı(id:{this.props.match.params.id})
                            </button>
                        </a>
                        <div className={"clear-both"}/>
                    </div>
                )}

                <div className="orange-card card shadow rounded-2 mb-3">
                    <div className="card-header">
                        <h5 className="card-title">Hakkımda</h5>
                    </div>
                    <div className="card-body">
                        <span className={"breakLine"}>{this.state.about}</span>
                    </div>
                </div>

                <div className="orange-card card shadow rounded-2 mb-3">
                    <div className="card-header">
                        <h5 className="card-title">İlgi Alanlarım</h5>
                    </div>
                    <div className="card-body">
                        {
                            interestsArray.map(function (interest) {
                                if (interest !== "")
                                    return (<a href={"/categoryDetail/" + interest.id}>
                                            <span
                                                className="btn-secondary badge my-interestsMobile">{interest.name}</span></a>
                                    )
                            })
                        }
                    </div>
                </div>

                <div className="orange-card card shadow rounded-2 mb-3">
                    <div className="card-header">
                        <h5 className="card-title">Şunları önerebilirim?</h5>
                    </div>
                    <div className="card-body">
                        <span className={"breakLine"}>{this.state.motivation}</span>
                    </div>
                </div>
                <div className="card shadow rounded-2 px-0 mb-5 container-fluid">
                    <button onClick={this.deleteAccount} className={"btn btn-danger"}><i
                        className="fas fa-times"/> Hesabımı Sil
                    </button>
                </div>
            </div>
        )
    }
}

export default MyProfileMobile;
