import React from "react";
import Globals from "../../util/Globals";
import Security from "../../security/Security";
import ProfilePicMobile from "../common/ProfilePicMobile";
import UserFullNameMobile from "../common/UserFullNameMobile";

const axios = require('axios');


class MessageBoxActivityMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
        };
        this.fillPage = this.fillPage.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.fillPage();

        const self = this;
        setInterval(function () {
            self.fillPage();
        }, 5000);
    }

    scrollToBottom() {
        const scrollHeight = this.messageList.scrollHeight;
        const height = this.messageList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    fillPage() {
        let self = this;
        let oldLength = this.state.messages.length;
        axios.get(Globals.serviceUrl + 'messageActivity/getMessages/' + this.props.activityId, Security.authHeader())
            .then(function (response) {
                self.setState({messages: response.data});
                if (response.data.length > oldLength)
                    self.scrollToBottom();
            })
    }

    async sendMessagee(messageDto) {
        const self = this;
        await axios.post(Globals.serviceUrl + 'messageActivity/send/', messageDto, Security.authHeader())
            .then(function (response) {
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });
        this.fillPage();
    }

    render() {
        return (
            <div className="imessage text-align-left mb-0" style={{'minHeight': '70vh'}} ref={(div) => {
                this.messageList = div;
            }}>
                {this.state.messages.map(function (message) {
                        let fromMe = false;
                        let msgClass = "from-them ";

                        if (message.writer.id.toString() === localStorage.getItem("userId")) {
                            msgClass = "from-me ";
                            fromMe = true;
                        }

                        return (
                            <p className={msgClass + "Container groupMessage " + (fromMe ? 'myGroupMessage':'themGroupMessage')}>
                                {/*<div className={msgClass}>*/}
                                {/*    <strong>*/}
                                {/*        {message.writer.premiumType==="GOLD" &&(*/}

                                {/*            <span className={'goldCheck'}><i className="far fa-check-circle"/>&nbsp;</span>*/}
                                {/*        )}*/}
                                {/*        {message.writer.premiumType==="SILVER" &&(*/}

                                {/*            <span className={'silverCheck'}><i className="far fa-check-circle"/>&nbsp;</span>*/}
                                {/*        )}*/}
                                {/*        <a href={"/profile/"+message.writer.id}> {message.writer.name+" "+message.writer.surname}</a></strong>*/}
                                {/*    <br/>*/}
                                {/*    <div dangerouslySetInnerHTML={{__html:message.message}}></div>*/}
                                {/*    <span className={"messageDate"}>*/}
                                {/*    {message.createdAt}*/}
                                {/*</span>*/}
                                {/*</div>*/}
                                <div className={"notificationUserName"}>
                                    <UserFullNameMobile
                                        cssClass={(fromMe ? 'userFullNameLight' : 'userFullName')}
                                        user={message.writer}
                                    />
                                </div>
                                <span>{message.message}</span>
                                <div className="d-flex align-items-end flex-column">
                                    <span style={{
                                        'fontSize': 10,
                                        'marginTop': '.25rem',
                                        'whiteSpace': 'nowrap'
                                    }}>{message.createdAt}</span>
                                </div>
                                <div className={" "+ (fromMe ? 'myGroupMessagePhoto':'themGroupMessagePhoto')}>
                                    <ProfilePicMobile
                                        userId={message.writer.id}
                                        profilePicName={message.writer.profilePicName}
                                        cssClass={"profilePicSmallMobile"}
                                    />
                                </div>
                            </p>
                        )
                    }
                )}
            </div>
        )
    }
}


export default MessageBoxActivityMobile;






