import React from "react";
import Security from "../../../security/Security";
import JSUtil from "../../../util/JSUtil";
import Globals from "../../../util/Globals";

const axios = require('axios');


class BaseActivityListMobile extends React.Component {
    constructor(props) {
        super(props);
        Security.protect();

        this.deleteActivity = this.deleteActivity.bind(this);
        this.joinActivity = this.joinActivity.bind(this);
        this.likeActivity = this.likeActivity.bind(this);
    }


    joinActivity(id){
        const self = this;

        let activities = self.state.activities;
        let currentMeetingOld = activities.filter(obj => {
            return obj.id === id
        });

        if (Security.isValidToken()) {
            axios.get(Globals.serviceUrl + 'vibe/vibePercentOfActivityOwner/' + id, Security.authHeader())
                .then(function (response) {


                    let question="Bu aktiviteye katılmak istediğinden emin misin?";
                    if(response.data<3.75 && response.data!==0){
                        question="Bu kişinin OLUMLU İZLENİM ORANI düşük, aktivitesine KATILMAMANI tavsiye ederiz";
                    }


                    if(currentMeetingOld[0].thisUserJoined===1 || currentMeetingOld[0].thisUserJoined===2)
                        question="Bu aktiviteden isteğini geri çekmek istediğine emin misin?";

                    let result=window.confirm(question);
                    if(!result)
                        return;

                    axios.get(Globals.serviceUrl+'request/sendRequest/'+id, Security.authHeader())
                        .then(function (response) {
                            let currentMeetingNew  =Object.assign({},currentMeetingOld)[0];
                            currentMeetingNew.thisUserJoined = response.data;

                            let indexOfChanged = 0;
                            activities.map(function (meeting,index) {
                                if(meeting.id===id)
                                    indexOfChanged=index;
                            });
                            activities[indexOfChanged] = currentMeetingNew;
                            self.setState({activities:activities});
                        })
                        .catch(function (error) {
                            alert(error.response.data.userWarningMessage);
                        });


                });
        }

    }


    likeActivity(id) {
        const self = this;

        let activities = self.state.activities;
        let currentMeetingOld = activities.filter(obj => {
            return obj.id === id
        });


        axios.get(Globals.serviceUrl+"like/like/" + id, Security.authHeader())
            .then(function (response) {
                let currentMeetingNew = Object.assign({}, currentMeetingOld)[0];
                currentMeetingNew.thisUserLiked = response.data;
                if(response.data==0)
                    currentMeetingNew.likeCount = currentMeetingNew.likeCount -1;
                if(response.data==1)
                    currentMeetingNew.likeCount = currentMeetingNew.likeCount +1;

                let indexOfChanged = 0;
                activities.map(function (meeting, index) {
                    if (meeting.id === id)
                        indexOfChanged = index;
                });
                activities[indexOfChanged] = currentMeetingNew;
                self.setState({activities: activities});
             }).catch(function (error) {
            alert(error.response.data.userWarningMessage);
        });
    }


    deleteActivity(id) {

        const self = this;
        if (!window.confirm("Dışarı cıkmaktan  vaz mı geçtin?"))
            return;

        axios.get(Globals.serviceUrl+"activity/delete/" + id, Security.authHeader())
            .then(res => {

                let meetings = self.state.activities;
                let meetingsNew = JSUtil.deleteFromArrayByPropertyName(meetings,"id",id );
                self.setState({activities: meetingsNew});
            }).catch(function (error) {
            alert(error.response.data.userWarningMessage);

        });
    }

}

export default BaseActivityListMobile;