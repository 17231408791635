import React from "react";
import Globals from "../../util/Globals";
import Security from "../../security/Security";

const axios = require('axios');


class MessageBoxMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: []
        };

        this.fillPage = this.fillPage.bind(this);
        this.fillPage();
        const self = this;
        setInterval(function () {
            self.fillPage();
        }, 5000);


        setTimeout(function () {
            self.scrollToBottom();
        }, 1600)
    }

    async sendMessagee(messageDto) {
        const self = this;
        let oldLength = this.state.messages.length;
        await  axios.post(Globals.serviceUrl + 'message/send/', messageDto, Security.authHeader())
            .then(function (response) {
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });
        this.fillPage();
    }

    fillPage() {
        //get messages
        let self = this;
        let oldLength = this.state.messages.length;

        axios.get(Globals.serviceUrl + 'message/getMessagesForReader/' + this.props.activityId, Security.authHeader())
            .then(function (response) {
                self.setState({messages: response.data});
                if (response.data.length > oldLength)
                    self.scrollToBottom();
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });
    }

    scrollToBottom() {
        const scrollHeight = this.messageList.scrollHeight;
        const height = this.messageList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    render() {

        return (
            <div className="imessage text-align-left mb-0" style={{'min-height':'70vh'}} ref={(div) => {
                this.messageList = div;
            }}>
                {this.state.messages.map(function (message) {
                        let msgClass = "from-me ";
                        console.log(message.reader.id);
                        if (message.reader.id.toString() === localStorage.getItem("userId")) {
                            msgClass = "from-them ";
                        }

                        return (
                            <p className={msgClass + "Container"}>
                                {message.message}

                                <p style={{'float':'right','margin':0,'fontSize':12,'white-space' : 'nowrap'}} >{message.createdAt}</p>
                            </p>)
                    }
                )}
            </div>
        )
    }
}


export default MessageBoxMobile;






