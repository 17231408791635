import React from "react";
import Security from "../../security/Security";
import classnames from "classnames";
import MessageBoxMobile from "./MessageBoxActivityMobile";
import Globals from "../../util/Globals";
import JsUtil from "../../util/JSUtil";

const axios = require('axios');


class MessageActivityPageMobile extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        Security.protect();

        this.state = {
            messages: [],
            message: "",
            activityId: this.props.match.params.id,
            errors: {}
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.sendLocation = this.sendLocation.bind(this);
        this.toggleButtonVisible = this.toggleButtonVisible.bind(this);

    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        let urlMessage = this.urlify(this.state.message);

        const message = {
            message: urlMessage,
            activityId: this.state.activityId
        };

        this.child.current.sendMessagee(message);
        this.setState({message: ""});
        //this.sendMessage(message);
    }

    sendLocation() {

        let confirm = window.confirm("Bu gruba konum göndermek istediğinden emin misin?(Şu an tarayıcıda çalışıyor, yakında uygulamada da açılacak)");

        if (!confirm)
            return false;

        let self = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let DMS = JsUtil.convertDMS(position.coords.latitude, position.coords.longitude);
                let locationMapsLink = '<a href="https://www.google.com/maps/place/' + DMS + '" target="_blank">KONUMUM</a>';

                const message = {
                    message: locationMapsLink,
                    activityId: self.state.activityId
                };

                self.sendMessage(message);
            });
        }
    }

    // sendLink(e) {
    //     e.preventDefault();
    //
    //     if (this.state.message == "") {
    //         alert("Linki mesaj kutusuna yapıştır ve bu butona tıkla");
    //         return false;
    //     }
    //
    //     const message = {
    //         message: "<a href='" + this.state.message + "'  target='_blank'>" + this.state.message + "</a>",
    //         activityId: this.state.activityId
    //     };
    //     this.sendMessage(message);
    // }

    urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '">' + url + '</a>';
        })
        // or alternatively
        // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }


    async sendMessage(messageDto) {
        const self = this;
        await axios.post(Globals.serviceUrl + 'messageActivity/send/', messageDto, Security.authHeader())
            .then(function (response) {
                //  let messages = self.state.messages;
                //   let newMessage = response.data;
                // messages.push(newMessage);
                // self.setState({messages: messages});
                self.setState({message: ""});
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });
        this.child.current.fillPage();
    }


    toggleButtonVisible() {
        this.setState(prevState => ({
            filterVisible: !prevState.filterVisible
        }));
    }

    render() {
        return (
            <div className={"container-fluid"} style={{'minHeight': '93vh'}}>

                <nav className="filter-navbar navbar shadow rounded-3 sticky-top messageNavbar">
                    <div className="container-fluid">
                        <div className="justify-content-start mb-1">
                            <a className="navbar-brand" href={"/activityDetail/" + this.props.match.params.id}
                               style={{'color': '#fff', 'fontWeight': '600'}}>
                                <strong>{"Aktivite " + this.state.activityId}</strong> </a>
                        </div>

                        <button className={this.state.filterVisible ? "btn btn-primary" : "btn btn-outline-primary"}
                                type="button" onClick={this.toggleButtonVisible}>
                            <i className="fa fa-ellipsis-v"/>
                        </button>
                        {this.state.filterVisible && (<div className="full-width mt-1">
                            <div className="d-flex flex-row-reverse">
                                <button onClick={this.sendLocation}
                                        className={"btn btn-danger ms-2"}>Konum
                                </button>
                                <a className={"complainOnMessagePage"}
                                   href={"/activityAlbum/" + this.props.match.params.id}>
                                    <button className={"btn btn-success"}>Fotoğraf</button>
                                </a>
                            </div>
                        </div>)}
                    </div>
                </nav>
                <br/>
                <MessageBoxMobile ref={this.child} activityId={this.props.match.params.id}/>
                <div style={{'textAlign': 'right'}}>
                    <form onSubmit={this.onSubmit}>
                        <div className="input-group">
                            <textarea
                                className={classnames("form-control ", {
                                    "is-invalid": this.state.errors.message
                                })}
                                placeholder="Bir mesaj yazın..."
                                name="message"
                                maxLength={255}
                                value={this.state.message}
                                onChange={this.onChange}
                            />
                            <div className="input-group-append">
                                <button className="btn btn-primary" style={{'height': '100%'}} type="submit">
                                    <i className="far fa-comment-dots"/>
                                </button>
                            </div>
                        </div>
                        <span className="mb-3">{this.state.message.length} / 255</span>
                    </form>
                    {this.state.errors.message && (
                        <div className="alert alert-danger">
                            {this.state.errors.message}
                        </div>
                    )}
                    {this.state.errors.userWarningMessage && (
                        <div>
                            {this.state.errors.userWarningMessage}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default MessageActivityPageMobile;
