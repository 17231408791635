import React from "react";
import classnames from "classnames";
import security from "../../../security/Security";
import AlertMobile from "../../common/AlertMobile";
import Globals from "../../../util/Globals";

const axios = require('axios');


class UpdatePasswordMobile extends React.Component {
    constructor(props) {
        super(props);
        security.protect();

        this.state = {
            newPassword: "",
            newPasswordConfirm: "",
            oldPassword: "",
            passwordUpdatedMessage: false,
            isSubmitDisabled: false,
            errors: {}
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }


    UpdatePassword(changePasswordDto) {
        let self = this;


        axios.post(Globals.serviceUrl + 'user/updatePassword', changePasswordDto, security.authHeader())
            .then(function (response) {
                console.log(response);
                self.setState({"passwordUpdatedMessage": "Şifreniz Güncellendi"});
                self.setState({"errors": {}});
            })
            .catch(function (error) {
                console.log(error.response);
                self.setState({"errors": error.response.data});
                self.setState({"passwordUpdatedMessage": false});
                self.setState({isSubmitDisabled: false});
            });
    }


    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({isSubmitDisabled: true});
        const newPassword = {
            newPassword: this.state.newPassword,
            oldPassword: this.state.oldPassword,
            newPasswordConfirm: this.state.newPasswordConfirm
        }
        this.UpdatePassword(newPassword);
    }


    render() {
        const {errors} = this.state;
        const {passwordUpdatedMessage} = this.state;


        return (
            <div className="container-fluid" style={{'minHeight': '93vh'}}>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            ŞİFRE GÜNCELLE
                        </a>
                    </div>
                </nav>
                <br/>
                {passwordUpdatedMessage && (
                    <AlertMobile type="alert-success" message={passwordUpdatedMessage}/>

                )}

                <form onSubmit={this.onSubmit}>
                    <div className="card container-fluid">
                        <div className="row text-align-left my-2">
                            <span className="profileEditLabel">Mevcut Şifre*</span>
                            <input
                                type="password"
                                className={classnames("form-control profileEditTextArea", {
                                    "is-invalid": errors.oldPassword
                                })}
                                name="oldPassword"
                                value={this.state.oldPassword}
                                onChange={this.onChange}
                            />
                            {errors.oldPassword && (
                                <div className="invalid-feedback">
                                    {errors.oldPassword}
                                </div>
                            )}
                        </div>

                        <div className="row text-align-left mb-2">
                            <span className="profileEditLabel">Yeni Şifre*</span>
                            <input
                                type="password"
                                className={classnames("form-control profileEditTextArea", {
                                    "is-invalid": errors.newPassword
                                })}
                                name="newPassword"
                                value={this.state.newPassword}
                                onChange={this.onChange}
                            />
                            {errors.newPassword && (
                                <div className="invalid-feedback">
                                    {errors.newPassword}
                                </div>
                            )}
                        </div>

                        <div className="row text-align-left mb-2">
                            <span className="profileEditLabel">Yeni Şifre Tekrar*</span>
                            <input
                                type="password"
                                className={classnames("form-control profileEditTextArea", {
                                    "is-invalid": errors.newPasswordConfirm
                                })}
                                name="newPasswordConfirm"
                                value={this.state.newPasswordConfirm}
                                onChange={this.onChange}
                            />
                            {errors.newPasswordConfirm && (
                                <div className="invalid-feedback">
                                    {errors.newPasswordConfirm}
                                </div>
                            )}
                        </div>

                        <input type="submit"
                            value={"Güncelle"}
                            className="btn btn-primary full-width btn-block my-2"
                            disabled={this.state.isSubmitDisabled}
                        />
                    </div>
                </form>
            </div>

    )
    }
    }

    export default UpdatePasswordMobile;
