import React from "react";
import Security from "../../security/Security";
import BaseActivityListMobile from "../activity/Base/BaseActivityListMobile";
import Globals from "../../util/Globals";
import ActivityListItemMobile from "../common/ActivityListItemMobile";
import Select from "react-select";
import CityUtil from "../../util/CityUtil";
import DateRangePicker from 'react-bootstrap-daterangepicker';

const moment = require("moment");

const axios = require('axios');
let self;

class AllActivitiesMobile extends BaseActivityListMobile {
    constructor() {
        super();

        this.state = {
            activities: [],
            // userCount: 0,
            cities: [],
            city: localStorage.getItem("city") || {},
            cityId: localStorage.getItem("cityId") || 1,
            attendanceRate: 0,
            pageNum: 0,
            loading: true,
            noMoreRecords: false,
            filterVisible: sessionStorage.getItem("filterVisible")==='true' || false,
            sDate: sessionStorage.getItem("sDate") || '',
            eDate: sessionStorage.getItem("eDate") || '',
            banaOzel: localStorage.getItem("filter") === '1',
            firebaseToken : localStorage.getItem("firebaseToken")
        };

        axios.get(Globals.serviceUrl + 'notification/newNotifications/', Security.authHeader())
            .then(function (response) {
                if (response.data.length > 0)
                    window.location = "/notifications";
            });

        this.loadMore = this.loadMore.bind(this);
        this.fillPage = this.fillPage.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.createUrl = this.createUrl.bind(this);
        this.removeDateSelection = this.removeDateSelection.bind(this);

        this.fillPage();
        this.loadCities();

        self = this;

        let isScrolling = false;
        let isScrolling2 = false;
        window.addEventListener('scroll', function (event) {
            window.clearTimeout(isScrolling);
            isScrolling = setTimeout(function () {
                if (self.state.loading === false) {
                    sessionStorage.setItem("scroll", window.scrollY.toString());
                }
            }, 300);

            window.clearTimeout(isScrolling2);
            isScrolling2 = setTimeout(function () {
                if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                    if (!self.state.noMoreRecords)
                    {
                        self.loadMore();
                    }
                }
            }, 100);
        }, false);
    }

    loadCount() {
        axios.get(Globals.serviceUrl + 'user/maleCount/', Security.authHeader())
            .then(function (response) {
                self.setState({maleCount: response.data});
            });
        axios.get(Globals.serviceUrl + 'user/femaleCount/', Security.authHeader())
            .then(function (response) {
                self.setState({femaleCount: response.data});
            });
    }

    async loadPages(pageNum) {
        for (let i = 0; i < pageNum; i++) {
            await this.loadMore();
        }
    }

    async loadMore() {
        const self = this;
        let newPageNum = this.state.pageNum + 1;
        this.setState({pageNum: newPageNum});

        await axios.get(this.createUrl(newPageNum), Security.authHeader())
            .then(function (response) {
                if (response.data.length === 0) {
                    self.setState({noMoreRecords: true});
                    return;
                }

                let newActivities = self.state.activities;
                newActivities = newActivities.concat(response.data);
                self.setState({activities: newActivities});
                sessionStorage.setItem("pageNum", newPageNum);
            });
    }

    createUrl(pageNum) {
        let filter = this.state.banaOzel ? 'findByCategoriesByCityId' : 'findAllByCityId';
        let sDate = sessionStorage.getItem("sDate");
        let eDate = sessionStorage.getItem("eDate");
        if (sDate !== null && eDate !== null) {
            return Globals.serviceUrl + 'activity/' + filter + '/' + this.state.cityId + "/" + sDate + "/" + eDate + "/" + pageNum;
        } else {
            return Globals.serviceUrl + 'activity/' + filter + '/' + this.state.cityId + "/" + pageNum;
        }
    }

    fillPage() {
        axios.get(this.createUrl(0), Security.authHeader())
            .then(function (response) {
                self.setState({activities: response.data});
                if (response.data.length === 0) {
                    self.setState({noActivity: true});
                    self.setState({"loading": false});
                    self.setState({"noMoreRecords": true});
                    return;
                } else {
                    self.setState({noActivity: false});
                }
                let pageNum = sessionStorage.getItem("pageNum");
                let scrollY = sessionStorage.getItem("scroll");
                if (pageNum > 0) {
                    self.loadPages(pageNum).then(function () {
                        window.scrollTo(0, parseInt(scrollY));
                        setTimeout(function () {
                            if (scrollY > 100)
                                window.scrollTo(0, parseInt(scrollY));
                        }, 1000);
                    }).finally(function () {
                        self.setState({"loading": false});
                    });
                } else {
                    setTimeout(function () {
                        window.scrollTo(0, parseInt(scrollY));
                        self.setState({"loading": false});
                    }, 1000);
                }
            })
            .catch(function (error) {
                console.log(error.response);
            });

        if(this.state.firebaseToken) {
            axios.get(Globals.serviceUrl + 'm/aios/' + this.state.firebaseToken, Security.authHeader())
                .then(function (response) {
                    console.log(response.data);
                })
                .catch(function (error) {
                    console.log(error.response.data);
                });
        }

        axios.get(Globals.serviceUrl + 'user/userAttendanceRate/' + localStorage.getItem("userId"), Security.authHeader())
            .then(function (response) {
                self.setState({attendanceRate: response.data});
            })
            .catch(function (error) {
            });
    }

    loadCities() {
        axios.get(Globals.serviceUrl + 'city/all/', Security.authHeader())
            .then(function (response) {
                let result = CityUtil.setCitiesForSelect(response.data);
                self.setState({cities: result.cities});
                self.setState({city: result.selectedCity});
            })
            .catch(function (error) {
            });
    }

    onSelectChange(e) {
        self.setState({
            city: e,
            cityId: e.value,
            pageNum: 0,
            noMoreRecords: false,
            loading: true
        }, () => {
            self.scrollTop();
            self.fillPage();
        });

        localStorage.setItem("cityId", e.value);
        localStorage.setItem("city", e);
    }

    scrollTop() {
        window.scroll(0, 0);
        sessionStorage.setItem("scroll", "0");
    }

    toggleFilterVisible() {
        sessionStorage.setItem("filterVisible", !self.state.filterVisible);
        self.setState(prevState => ({
            filterVisible: !prevState.filterVisible
        }));
    }

    removeDateSelection() {
        sessionStorage.removeItem("sDate");
        sessionStorage.removeItem("eDate");
        self.setState({
            sDate: '',
            eDate: '',
            pageNum: 0,
            noMoreRecords: false,
            loading: true
        }, () => {
            self.scrollTop();
            self.fillPage();
        });
    }

    selectDate(event, picker) {
        sessionStorage.setItem("sDate", picker.startDate.format('DD-MM-YYYY'));
        sessionStorage.setItem("eDate", picker.endDate.format('DD-MM-YYYY'));
        self.setState({
            sDate: picker.startDate.format('DD-MM-YYYY'),
            eDate: picker.endDate.format('DD-MM-YYYY'),
            pageNum: 0,
            noMoreRecords: false,
            loading: true
        }, () => {
            self.scrollTop();
            self.fillPage();
        });
    }

    setBanaOzel() {
        localStorage.setItem("filter", 1);
        self.setState({
           banaOzel: true
        });
        self.removeDateSelection();
    }

    setAllActivities() {
        localStorage.setItem("filter", 0);
        self.setState({
            banaOzel: false
        });
        self.removeDateSelection();
    }

    render() {
        const self = this;
        let pageOpacity = 0;
        //let kadin = 16000 - this.state.femaleCount;
        //let erkek = 4000 - this.state.maleCount;
        return (
            <div className="container-fluid" style={{'minHeight': '93vh'}}>
                {!this.state.loading && (pageOpacity = true)}
                {this.state.loading && (
                    <div className="spinner-border" style={{
                        'color': '#fd7e14',
                        'position': 'fixed',
                        'top': '50vh',
                        'left': '45vw',
                        'width': '3rem',
                        'height': '3rem'
                    }} role="status">
                        <span className="sr-only">Yükleniyor...</span>
                    </div>
                )}

                {localStorage.getItem("cityId") === "null" && (

                    <a href={"updateInfo/"}>
                        <div className="alert alert-primary" role="alert">
                            <strong><i className="fas fa-map-marker-alt"/> Yaşadığın şehri seçmek için tıkla</strong>
                        </div>
                        <br/>
                    </a>
                )}

                <div style={{opacity: pageOpacity}}>
                    <br/>
                    <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                        <div className="container-fluid">
                            {this.state.banaOzel && (
                                <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                                    BANA ÖZEL
                                </a>
                            )}
                            {!this.state.banaOzel && (
                                <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                                    TÜM AKTİVİTELER
                                </a>
                            )}

                            <button className={this.state.filterVisible ? "btn btn-primary" : "btn btn-outline-primary"}
                                    type="button" onClick={this.toggleFilterVisible}>
                                <i className="fa fa-filter"/> Filtrele
                            </button>
                            {this.state.filterVisible && (<div className="full-width mt-1">
                                <div className="row">
                                    <div className="col-6">
                                        <Select value={this.state.city} options={this.state.cities}
                                                onChange={this.onSelectChange}/>
                                    </div>
                                    <div className="col-6">
                                        {!this.state.banaOzel && (
                                            <button className="btn btn-primary full-width" onClick={this.setBanaOzel}>
                                                Bana Özel
                                            </button>
                                        )}
                                        {this.state.banaOzel && (
                                            <button className="btn btn-primary full-width" onClick={this.setAllActivities}>
                                                Tüm Aktiviteler
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-2">
                                        <div id="activityFilter" className="input-group mb-3">
                                            <span className="input-group-text">
                                                <i className="fa fa-calendar-alt"/>
                                            </span>
                                            <DateRangePicker initialSettings={{
                                                parentEl: "#activityFilter",
                                                linkedCalendars: false,
                                                ranges: {
                                                    'Bugün': [moment(), moment()],
                                                    'Yarın': [moment().add(1, 'days'), moment().add(1, 'days')],
                                                    'Bu Hafta': [moment(), moment().endOf('week')],
                                                    'Gelcek Hafta': [moment().add(1,'week').startOf('week') ,moment().add(1,'week').endOf('week') ],
                                                    'Bu Ay': [moment(), moment().endOf('month')],
                                                }, locale: {
                                                    "format": "DD/MM/YYYY",
                                                    "applyLabel": "Uygula",
                                                    "cancelLabel": "İptal",
                                                    "customRangeLabel": "Özel Aralık",
                                                    "fromLabel": "Başlangıç",
                                                    "toLabel": "Bitiş",
                                                    "weekLabel": "H",
                                                    "daysOfWeek": [
                                                        "Pa",
                                                        "Pzt",
                                                        "Sa",
                                                        "Çar",
                                                        "Per",
                                                        "Cu",
                                                        "Cmt"
                                                    ],
                                                    "monthNames": [
                                                        "Ocak",
                                                        "Şubat",
                                                        "Mart",
                                                        "Nisan",
                                                        "Mayıs",
                                                        "Haziran",
                                                        "Temmuz",
                                                        "Ağustos",
                                                        "Eylül",
                                                        "Ekim",
                                                        "Kasım",
                                                        "Aralık"
                                                    ],
                                                    "firstDay": 1
                                                }, startDate: this.state.sDate || moment(),
                                                endDate: this.state.eDate || moment(),
                                                minDate: moment(),
                                                autoUpdateInput: false,
                                            }} onApply={this.selectDate}>
                                                <input type="text" className="form-control"
                                                       value={this.state.sDate + ' ' + this.state.eDate} readOnly={true}/>
                                            </DateRangePicker>

                                            <button className="btn btn-primary" type="button" onClick={this.removeDateSelection}>
                                                <i className="fa fa-trash-alt"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>)}
                        </div>
                    </nav>
                    {this.state.attendanceRate > 3 && this.state.attendanceRate < 70 && (
                        (
                            <div className={"mt-2 alert alert-danger"}>
                                Onaylandığın aktivitelere katılım oranın düşük, lütfen sadece katılmayı düşündüğün
                                aktivitelere istek gönder.
                                Onaylandığın zaman gerçekten planlarını ona göre yapan ve seni bekleyen insanlar var!

                            </div>
                        )
                    )}

                    {(this.state.noActivity && this.state.banaOzel) && (
                        <div className="col-12 alert alert-info mt-3 mb-0">
                                        <span>
                                            Eğer seçmediysen <a href={"/categories"}>BURAYA TIKLAYIP</a> ilgi alanlarını seçmelisin. <br/>
                                            Eğer seçtiysen sen bir aktivite oluştur. Unutma, seninle aynı şeylere ilgi duyan birrrsürü insan aktivite açmanı bekliyor.
                                        </span>
                        </div>
                    )}

                    {(this.state.noActivity && !this.state.banaOzel) && (
                        <div className="col-12 alert alert-info mt-3 mb-0">
                            <span>
                                Kriterlere uygun aktivite bulunamadı.
                                İstersen sen bir aktivite oluştur. Unutma, seninle aynı şeylere ilgi duyan bir sürü insan aktivite açmanı bekliyor.
                            </span>
                        </div>
                    )}

                    {
                        self.state.activities.map(function (activity, i) {
                            return (
                                <ActivityListItemMobile activity={activity} deleteActivity={self.deleteActivity}
                                                        joinActivity={self.joinActivity}
                                                        likeActivity={self.likeActivity}
                                                        key={i}/>
                            );
                        })}
                    {/*<span className={"discoverInfo"}>Toplam kullanıcı sayısı: {this.state.userCount}</span><br/>*/}
                    <button hidden={this.state.noMoreRecords} className={"btn btn-primary"} onClick={this.loadMore}>Daha
                        fazla göster...
                    </button>

                    <div className={"scrollTopMobile"}>
                    <span onClick={this.scrollTop}><i
                        className="fas fa-arrow-alt-circle-up scrollTopArrow"/></span>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        )
    }
}

export default AllActivitiesMobile;
