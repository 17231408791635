import React from "react";


class ContactMobile extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {


        return (
            <div className="full-width text-align-left container" style={{'minHeight': '93vh'}}>
                    <h4>İletişim!</h4>
                    <hr/>
                    Her türlü geri dönüşünüz için instagramdan(@actizone),  actizonetr@gmail.com(bunu tavsiye etmiyoruz, mail atmak çok yavaş bir iletişim şekli) veya Whatsapp 0507 605 41 24 kanalları üzerinden bizimle iletişime geçebilirsiniz.<br/>
                    Yeni, amatör ve sıfırdan başlayan bu projemizde her türlü fikir bizim için değerlidir.
            </div>

        )
    }
}

export default ContactMobile;
