import React from "react";
import Security from "../security/Security";
import BaseActivityListMobile from "./activity/Base/BaseActivityListMobile";
import CityUtil from "../util/CityUtil";
import Select from 'react-select'
import Globals from "../util/Globals";
import ActivityListItemMobile from "./common/ActivityListItemMobile";


const axios = require('axios');
let self;

class DashboardMobile extends BaseActivityListMobile {
    constructor() {
        super();

        this.state = {
            activities: [],
            // userCount: 0,
            maleCount: 0,
            femaleCount: 0,
            cities: [],
            city: {},
            noActivity:false,
            sponsor: false,
            attendanceRate: 0,
            pageNum: 0,
            loading: true,
            noMoreRecords: false,
            filterVisible: false
        };
        axios.get(Globals.serviceUrl + 'notification/newNotifications/', Security.authHeader())
            .then(function (response) {
                if (response.data.length > 0)
                    window.location = "/notifications";
            });

        this.loadMore = this.loadMore.bind(this);
        this.fillPage = this.fillPage.bind(this);
        // this.loadCount = this.loadCount.bind(this);
        //   this.loadCount();

        let cityId = localStorage.getItem("cityId");
        // if (cityId === "null") {
        //     cityId = 1;
        // }

        this.fillPage(cityId);

        this.loadCities();
        localStorage.setItem("filter", "1");

        let isScrolling = false;
        window.addEventListener('scroll', function (event) {
            window.clearTimeout(isScrolling);
            isScrolling = setTimeout(function () {
                localStorage.setItem("scroll", window.scrollY.toString());
            }, 300);

        }, false);

        self = this;
        window.onscroll = function (ev) {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                if(!self.state.noMoreRecords)
                self.loadMore();
            }
        };
    }


    async loadMore() {
        const self = this;
        let newPageNum = this.state.pageNum + 1;
        this.setState({pageNum: newPageNum});
        let cityId;
        if(this.state.city.value==undefined)
            cityId=1;
        else
            cityId=this.state.city.value;

        await axios.get(Globals.serviceUrl + 'activity/findByCategoriesByCityId/' +  cityId+ "/" + newPageNum, Security.authHeader())
            .then(function (response) {

                if (response.data.length === 0) {
                    self.setState({noMoreRecords: true});
                    return;
                }

                let newActivities = self.state.activities;
                newActivities = newActivities.concat(response.data);
                self.setState({activities: newActivities});
                localStorage.setItem("pageNum", newPageNum);

            });
    }


    loadCount() {
        axios.get(Globals.serviceUrl + 'user/maleCount/', Security.authHeader())
            .then(function (response) {
                self.setState({maleCount: response.data});
            });
        axios.get(Globals.serviceUrl + 'user/femaleCount/', Security.authHeader())
            .then(function (response) {
                self.setState({femaleCount: response.data});
            });
    }

    async loadPages(pageNum) {
        for (let i = 0; i < pageNum; i++) {
            await this.loadMore();
        }
    }

    fillPage(cityId) {
        const self = this;
        if(cityId === "null")
            cityId = 1;

        axios.get(Globals.serviceUrl + 'activity/findByCategoriesByCityId/' + cityId + "/0", Security.authHeader())
            .then(function (response) {
                self.setState({activities: response.data});

                if (response.data.length === 0) {
                    self.setState({noActivity: true});
                    self.setState({"loading": false});
                    self.setState({"noMoreRecords": true});
                    return;
                } else {
                    self.setState({noActivity: false});
                }

                let pageNum = localStorage.getItem("pageNum");
                let scrollY = localStorage.getItem("scroll");
                if (pageNum > 0) {
                    self.loadPages(pageNum).then(function () {
                        window.scrollTo(0, parseInt(scrollY));
                        setTimeout(function () {
                            if(scrollY > 100)
                                window.scrollTo(0, parseInt(scrollY));
                        }, 1000);
                        self.setState({"loading": false});
                    });
                } else {
                    setTimeout(function () {
                        window.scrollTo(0, parseInt(scrollY));
                        self.setState({"loading": false});
                    }, 1000);
                }
            })
            .catch(function (error) {
                console.log(error.response);
            });

        axios.get(Globals.serviceUrl + 'm/ok', Security.authHeader())
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
            });
        // axios.get(Globals.serviceUrl + 'discover/findRandom', Security.authHeader())
        //     .then(function (response) {
        //         self.setState({sponsor: response.data});
        //     })
        //     .catch(function (error) {
        //     });
        axios.get(Globals.serviceUrl + 'user/userAttendanceRate/' + localStorage.getItem("userId"), Security.authHeader())
            .then(function (response) {
                self.setState({attendanceRate: response.data});
            })
            .catch(function (error) {
            });

        // axios.get(Globals.serviceUrl + 'user/userCount', Security.authHeader())
        //     .then(function (response) {
        //         self.setState({userCount: response.data});
        //     })
        //     .catch(function (error) {
        //     });
    }

    loadCities() {
        const self = this;
        axios.get(Globals.serviceUrl + 'city/all/', Security.authHeader())
            .then(function (response) {
                let result = CityUtil.setCitiesForSelect(response.data);
                self.setState({cities: result.cities});
                self.setState({city: result.selectedCity});
            })
            .catch(function (error) {
            });
    }

    onSelectChange(e) {
        self.fillPage(e.value);
        self.setState({city: e});
        self.setState({pageNum: 0});
        self.setState({noMoreRecords: false});
    }

    scrollTop() {
        window.scroll(0, 0);
        localStorage.setItem("pageNum", "0");
        localStorage.setItem("scroll", "0");

    }

    toggleFilterVisible() {
        self.setState(prevState => ({
            filterVisible: !prevState.filterVisible
        }));
    }

    render() {
        const self = this;
        let pageOpacity = 0;
        let kadin = 16000 - this.state.femaleCount;
        let erkek = 4000 - this.state.maleCount;
        return (
            <div className="full-width container-fluid" style={{'minHeight': '93vh'}}>
                {!this.state.loading && (
                    pageOpacity = true)
                }
                {this.state.loading && (
                    <div className="spinner-border" style={{'color': '#fd7e14', 'position':'fixed', 'top':'50vh', 'left':'45vw', 'width':'3rem', 'height':'3rem'}} role="status">
                        <span className="sr-only">Yükleniyor...</span>
                    </div>
                )}

                {localStorage.getItem("cityId") === "null" && (

                    <a href={"updateInfo/"}>
                        <div className="alert alert-primary" role="alert">
                            <strong><i className="fas fa-map-marker-alt"/> Yaşadığın şehri seçmek için tıkla</strong>
                        </div>
                        <br/>
                    </a>
                )}

                <div style={{opacity: pageOpacity}}>
                    <br/>
                    <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                                BANA ÖZEL
                            </a>
                            <button className={this.state.filterVisible ? "btn btn-primary" : "btn btn-outline-primary"}
                                    type="button" onClick={this.toggleFilterVisible}>
                                <i className="fa fa-filter"/>
                            </button>
                            {this.state.filterVisible && (<div className="full-width mt-1">
                                <div className="row">
                                    <div className="col-6">
                                        <Select value={this.state.city} options={this.state.cities}
                                                onChange={this.onSelectChange}/>
                                    </div>
                                    <div className="col-6 mt-2">
                                        <div className="bg-white rounded-2 shadow">
                                            <a className={"profileTitleMobile"} href={"/allActivities"}>Tüm Aktiviteler</a>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </nav>

                    {this.state.attendanceRate > 3 && this.state.attendanceRate < 70 && (
                        (
                            <div className={"mt-2 alert alert-danger"}>
                                Onaylandığın aktivitelere katılım oranın düşük, lütfen sadece katılmayı düşündüğün
                                aktivitelere istek gönder.
                                Onaylandığın zaman gerçekten planlarını ona göre yapan ve seni bekleyen insanlar var!

                            </div>
                        )
                    )}

                    {/*<strong><a href={"/top100"}><i className="fas fa-trophy"/> TOP 100</a></strong><br/><br/>*/}
                    {/*<strong><a href={"/help"}>
                        <button className={"btn btn-primary"}> <strong>Premium Ol!</strong></button>
                    </a></strong><br/>
                    <span style={{fontSize:"13px"}}>Actizone'un herhangi bir reklam veya sponsorluk geliri yok. Sadece premium üyelikler sayesinde varlığını sürdürebiliyor.
                    </span>*/}

                    {this.state.noActivity && (
                        <div className="col-12 alert alert-info mt-3 mb-0">
                                        <span>
                                            Eğer seçmediysen <a href={"/categories"}>BURAYA TIKLAYIP</a> ilgi alanlarını seçmelisin. <br/>
                                            Eğer seçtiysen sen bir aktivite oluştur. Unutma, seninle aynı şeylere ilgi duyan birrrsürü insan aktivite açmanı bekliyor.
                                        </span>
                        </div>
                    )}

                    {this.state.sponsor && localStorage.getItem("cityId") != "4" && (
                        <div className={"activityListActivityDetailMobile"}>
                            {this.state.sponsor.photoName != null &&
                            (<img src={'/upload/' + this.state.sponsor.photoName} width={"100%"}/>)
                            }
                            {this.state.sponsor.detail}
                        </div>
                    )}

                    {
                        self.state.activities.map(function (activity, i) {
                            return (
                                <ActivityListItemMobile activity={activity} deleteActivity={self.deleteActivity}
                                                        joinActivity={self.joinActivity} likeActivity={self.likeActivity}/>
                            );
                        })}
                    {/*<span className={"discoverInfo"}>Toplam kullanıcı sayısı: {this.state.userCount}</span><br/>*/}
                    <button hidden={this.state.noMoreRecords} className={"btn btn-primary"} onClick={this.loadMore}>Daha
                        fazla göster...
                    </button>

                    <div className={"scrollTopMobile"}>
                    <span onClick={this.scrollTop}><i
                        className="fas fa-arrow-alt-circle-up scrollTopArrow"/></span>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        )
    }
}

export default DashboardMobile;
