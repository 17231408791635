import React from "react";
import classnames from "classnames";
import security from "../../../security/Security";
import Validator from "../../../util/Validator";
import InputMask from "react-input-mask";
import AlertMobile from "../../common/AlertMobile";
import Select from 'react-select'
import CityUtil from "../../../util/CityUtil";
import Globals from "../../../util/Globals";

const axios = require('axios');
const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class UpdateInfoMobile extends React.Component {
    constructor(props) {
        super(props);
        security.protect();

        this.state = {
            name: "",
            surname: "",
            email: "",
            nick:"",
            bDateString: "",
            phone: "",
            gender: "UNSELECTED",
            referenceCode: "",
            savedMessage: "",
            about: "",
            motivation: "",
            city: {},
            cities: [],
            errors: {}
        };

        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.fillFields();
    }

    fillFields() {
        console.log(this.props);
        let self = this;

        axios.get(Globals.serviceUrl+'user/myProfile/', security.authHeader())
            .then(function (response) {
                self.setState(response.data);
                self.setState({"errors": {}});
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });
        this.initCities();
    }

    initCities() {


        let self = this;
        axios.get(Globals.serviceUrl+'city/all/', security.authHeader())
            .then(function (response) {
                let result = CityUtil.setCitiesForSelect(response.data);
                self.setState({cities: result.cities});
                self.setState({city: result.selectedCity});
            })
            .catch(function (error) {
            });
    }

    updateUser(newUser) {
        let self = this;
        axios.post(Globals.serviceUrl+'user/updateInfo', newUser, security.authHeader())
            .then(function (response) {
                self.setState({"errors": {}});
                self.setState({"savedMessage": "Bilgilerin Güncellendi"});
                localStorage.setItem("cityId", response.data.cityId);
                self.initCities();
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
                self.setState({"savedMessage": false});
                console.log(error.response);
            });
    }


    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSelectChange(e) {
        this.setState({city: e});
    }

    onSubmit(e) {
        e.preventDefault();

        let phoneValidationResult = Validator.validatePhoneNumber(this.state.phone);
        if (!phoneValidationResult.valid) {
            let errorUpdated = {...this.state.errors}
            errorUpdated.phone = "Telefon numarası uygun formatta değil";
            this.setState({"errors": errorUpdated})
            return;
        }


        const newUser = {
            id: localStorage.getItem("userId"),
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.email.trim(),
            phone: phoneValidationResult.phoneNumer,
            bDateString: this.state.bDateString,
            gender: this.state.gender,
            referenceCode: this.state.referenceCode,
            about: this.state.about,
            nick:this.state.nick,
            cityId: this.state.city.value,
            motivation: this.state.motivation,
        };
        this.updateUser(newUser);
    }


    render() {
        const {errors} = this.state;
        const {savedMessage} = this.state;

        return (
            <div className="container-fluid" style={{'minHeight': '93vh'}}>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top mb-2">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            PROFİL DÜZENLE
                        </a>
                    </div>
                </nav>

                <form onSubmit={this.onSubmit}>
                    <div className="card container-fluid">
                        <div className="row text-align-left my-2">
                            <span className="profileEditLabel">İsim*</span>
                            <input
                                type="text"
                                className={classnames("form-control profileEditTextArea", {
                                    "is-invalid": errors.name
                                })}
                                name="name"
                                value={this.state.name}
                                onChange={this.onChange}
                            />
                            {errors.name && (
                                <div className="invalid-feedback">
                                    {errors.name}
                                </div>
                            )}
                        </div>
                        <div className="row text-align-left mb-2">
                            <span className="profileEditLabel">Soyisim*</span>
                            <input
                                type="text"
                                className={classnames("form-control profileEditTextArea", {
                                    "is-invalid": errors.surname
                                })}
                                name="surname"
                                value={this.state.surname}
                                onChange={this.onChange}
                            />
                            {errors.surname && (
                                <div className="invalid-feedback">
                                    {errors.surname}
                                </div>
                            )}
                        </div>
                        <div className="row text-align-left mb-2">
                            <span className="profileEditLabel">Email*</span>
                            <input
                                type="text"
                                className={classnames("form-control profileEditTextArea", {
                                    "is-invalid": errors.email
                                })}
                                name="email"
                                value={this.state.email}
                                onChange={this.onChange}
                            />
                            {errors.email && (
                                <div className="invalid-feedback">
                                    {errors.email}
                                </div>
                            )}
                        </div>
                        <div className="row text-align-left mb-2">
                            <span className="profileEditLabel">Yaşadığın Şehir</span>
                            <Select className="profileEditSelect" value={this.state.city} options={this.state.cities}
                                    onChange={this.onSelectChange}/>
                            <div></div>
                            {errors.cityId && (
                                <div>
                                    {errors.cityId && (errors.cityId)}
                                </div>

                            )}
                        </div>
                        <div className="row text-align-left mb-2">
                            <span className="profileEditLabel">Telefon*</span>
                            <InputMask
                                mask="0599 999 9999"
                                type="text"
                                className={classnames("form-control profileEditTextArea", {
                                    "is-invalid": errors.phone
                                })}
                                name="phone"
                                value={this.state.phone}
                                onChange={this.onChange}
                                required
                                disabled={true}
                            />
                            {errors.phone && (
                                <div className="invalid-feedback">
                                    {errors.phone}
                                </div>
                            )}
                        </div>
                        {/*<div className="row text-align-left mb-2">
                            <span className="profileEditLabel">Takma Ad</span>
                            <input
                                type="text"
                                className={classnames("form-control profileEditTextArea", {
                                    "is-invalid": errors.nick
                                })}
                                name="nick"
                                value={this.state.nick}
                                onChange={this.onChange}
                            />
                            {errors.surname && (
                                <div className="invalid-feedback">
                                    {errors.surname}
                                </div>
                            )}
                        </div>*/}
                        <div className="row text-align-left mb-2">
                            <span className="profileEditLabel">Doğum Tarihi</span>
                            <InputMask
                                mask="99/99/9999"
                                type="text"
                                className={classnames("form-control profileEditTextArea", {
                                    "is-invalid": errors.bDateString
                                })}
                                placeholder="GG/AA/YYYY"
                                name="bDateString"
                                value={this.state.bDateString}
                                onChange={this.onChange}
                            />
                            {errors.bDateString && (
                                <div className="invalid-feedback">
                                    {errors.bDateString}
                                </div>
                            )}
                        </div>
                        <div className="row text-align-left mb-2">
                            <span className="profileEditLabel">Kısaca Kendinden Bahset</span>
                            <textarea
                                className={classnames("form-control profileEditTextArea")}
                                name="about"
                                rows={3}
                                value={this.state.about}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="row text-align-left mb-2">
                            <span className="profileEditLabel">Bize film, kitap, şarkı önerir misin?</span>
                            <textarea
                                className={classnames("form-control profileEditTextArea")}
                                placeholder="Godfather, Suç ve Ceza, Hotel California..."
                                name="motivation"
                                rows={3}
                                value={this.state.motivation}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="row text-align-left mb-4">
                            <span className="profileEditLabel">Cinsiyet*</span>
                            <input
                                className={classnames("form-control profileEditTextArea")}
                                type="text"
                                name="gender"
                                value={this.state.gender === "MALE" ? "Erkek" : "Kadın"}
                                disabled={true}
                            />
                        </div>
                    </div>
                    {savedMessage && (
                        <AlertMobile type="alert-success" message={savedMessage}/>
                    )}

                    <input
                        type="submit" value={"Güncelle"}
                        className="btn btn-primary full-width btn-block mt-4"
                    />
                    <br/><br/>
                </form>
            </div>

        )
    }
}

export default UpdateInfoMobile;
