import React from "react";
import Security from "../../security/Security";
import Globals from "../../util/Globals";

const axios = require('axios');

let self;

class ConversationsActivityMobile extends React.Component {
    constructor(props) {
        super(props);
        Security.protect();

        this.state = {
            conversations: [],
            errors: {},
            pageNum: 0,
            noMoreRecords: false
        };
        this.fillPage = this.fillPage.bind(this);
        this.loadMore = this.loadMore.bind(this);

        this.fillPage(0);

        self = this;
        window.onscroll = function (ev) {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                if (!self.state.noMoreRecords)
                    self.loadMore();
            }
        };
    }

    loadMore() {
        let newPageNum = this.state.pageNum + 1;
        this.setState({pageNum: newPageNum});
        this.fillPage(newPageNum);
    }

    fillPage(pageNum) {

        let self = this;

        axios.get(Globals.serviceUrl + 'activity/findByUserId/' + localStorage.getItem("userId") + "/" + pageNum, Security.authHeader())
            .then(function (response) {

                if (response.data.length == 0) {
                    self.setState({noMoreRecords: true});
                    return;
                }


                let arr = response.data;
                let arr2 = arr.sort(function (a, b) {
                    return b.id - a.id;
                });


                let newConversations = self.state.conversations;
                newConversations = newConversations.concat(arr2);
                self.setState({conversations: newConversations});

            })
    }

    readedClass(newMessage) {
        if (newMessage)
            return "newMsg";
        else
            return "readedMsg"
    }

    render() {
        const self = this;
        let i = 0;
        return (
            <div className="container-fluid" style={{'minHeight': '93vh'}}>

                <br/>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            AKTİVİTE MESAJLARI
                        </a>
                        <a href={"conversations"}>
                            <button className={"btn btn-primary"} type="button">
                                <i className="fas fa-user"/>
                            </button>
                        </a>
                    </div>
                </nav>

                {
                    this.state.conversations.map(function (conversation) {
                        i++;
                        if (i > 70)
                            return null;

                        return (
                            <div className={"card shadow rounded-2 my-2 conversationContainer " + self.readedClass(conversation.newMessage)}>
                                <a className="ms-1 my-2" style={{'textDecoration':'none'}} href={"messageActivity/" + conversation.id}>
                                    <strong className="m-0" style={{'color':'#563918','textAlign':'center'}}>Aktivite {conversation.id} (<strong>{conversation.profileDto.name + " " + conversation.profileDto.surname}</strong>)
                                    </strong>
                                    <p className="m-0" style={{'color':'#000000','textAlign':'left'}}>{conversation.detail.substring(0, 100) + "..."}</p>
                                </a>
                            </div>
                        )
                    })
                }
                <br/>
                <button hidden={this.state.noMoreRecords} className={"btn btn-primary"} onClick={this.loadMore}>Daha
                    fazla göster...
                </button>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>

            </div>
        );
    }
}


export default ConversationsActivityMobile;
