import React from "react";
import classnames from "classnames";

class ActivityDetailInputMobil extends React.Component {
    constructor(props) {
        super(props)
    }


    render() {

        return (
            <div className="row my-3">
                <div className="col-12 mt-2">
                    <textarea
                        className={classnames("form-control", {
                            "is-invalid": this.props.error
                        })}
                        placeholder="Ne yapmak, nereye gitmek istiyorsun? Bize biraz detay ver :)"
                        name="detail"
                        value={this.props.detail}
                        onChange={this.props.onChange}
                        rows={6}
                    />

                    {this.props.error && (
                        <div className="alert-danger my-1 rounded-3">
                            {this.props.error}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}


export default ActivityDetailInputMobil;

