import React from "react";


class HelpMobile2 extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {


        return (
            <div className="full-width text-align-left container" style={{'minHeight': '93vh'}}>



                Merhaba,<br/>
                Artık yorum yazmak için 2 değil, 5 gününüz var :) fakat bu durumda yazılan yorumlar 5 gün sonra gözüküyor olacak.
                <br/>
                Teşekkürler
                <br/>

                <br/><br/>

            </div>

        )
    }
}

export default HelpMobile2;
