import React from "react";
import Security from "../../security/Security";
import UserFullNameMobile from "../common/UserFullNameMobile";
import ProfilePicMobile from "../common/ProfilePicMobile";
import Globals from "../../util/Globals";

const axios = require('axios');


//meeting detail sayfası hazır, review detail sayfası yapılacak

class NotificationsMobile extends React.Component {
    constructor() {
        super();
        Security.protect()

        this.state = {
            notifications: [],
            erorrs: {}
        };

        this.fillPage();
    }

    toMessage(id, notId) {
        window.location = "/message/" + id;
        /* axios.get(Globals.serviceUrl + 'notification/delete/' + notId, Security.authHeader())
             .then(function (response) {
                 window.location = "/message/" + id;
             });*/
    }

    toMessageActivity(id, notId) {
        window.location = "/messageActivity/" + id;
        /*  axios.get(Globals.serviceUrl + 'notification/delete/' + notId, Security.authHeader())
              .then(function (response) {
                  window.location = "/messageActivity/" + id;
              });*/
    }

    fillPage() {

        if (localStorage.getItem("userId") === "5635") {
            window.location = "/logout";
        }

        const self = this;
        axios.get(Globals.serviceUrl + 'notification/allNotifications/', Security.authHeader())
            .then(function (response) {
                self.setState({notifications: response.data});
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });
        axios.get(Globals.serviceUrl + 'notification/readMessages', Security.authHeader());
        axios.get(Globals.serviceUrl + 'notification/readExceptMessages', Security.authHeader());

    }


    newInvite(id) {
        return (
            <span>
            seni <a href={"/activityDetail/" + id}>aktivitesine</a> davet ediyor, ilgini çekebilir.
            </span>
        )
    }

    newReminderText(id) {
        return (
            <span>
            ile  <a href={"/activityDetail/" + id}>bir aktiviten</a> var, hem de BUGÜN!!.
            </span>
        )
    }


    newRequestText(id) {
        return (
            <span>
            <a href={"/activityRequests/" + id}>Paylaştığın aktiviteye</a> katılmak istiyor.
            </span>
        )
    }

    newMessageText(id, notId) {
        return (
            <span>
                sana bir <strong onClick={() => this.toMessage(id, notId)}>bir mesaj</strong> gönderdi.
            </span>
        )
    }

    newMessageActivityText(id, notId) {
        return (
            <span>
                GRUBA bir <strong onClick={() => this.toMessageActivity(id, notId)}>bir mesaj</strong> gönderdi.
            </span>
        )
    }

    newReviewText(id) {
        return (
            <span>
                senin için <a href={"/review/" + id}>bir yorum</a> yazdı.
            </span>
        )
    }

    newFollowText() {
        return (
            <span>
                seni <a href={"/myFollowers/"}>takip etti</a> .
            </span>
        )
    }

    newRequestApprovalText(id) {
        return (
            <span>
                katılmak istediğin  <a
                href={"/activityDetail/" + id}>aktivitesi</a> için seni onayladı. İyi eğlenceler<br/>
            </span>
        )
    }

    newActivityText(id) {
        return (
            <span>
            <a href={"/activityDetail/" + id}>yeni bir aktivite</a> paylaştı.
            </span>
        )
    }

    newMeetingCommentAvailable(id) {
        return (
            <span>
            Yakın zamanda <a href={"/activityDetail/" + id}>bir aktiviteye</a> katıldın. Tanıştığın kişilerle ilgili olumlu/olumsuz oy vermeyi ve yorum yazmayı unutma:)
            </span>
        )
    }

    readedClass(isRead) {
        if (isRead)
            return "readedNotification";
        else
            return "newNotification"
    }

    render() {
        const self = this;
        return (
            <div className="full-width container-fluid" style={{'minHeight': '93vh'}}>

                <br/>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            BİLDİRİMLER
                        </a>
                    </div>
                </nav>

                {(this.state.notifications.length === 0) && (
                    <div className="alert alert-info mt-3 mb-0">
                        <h4>Hiç bildirim yok!</h4>
                    </div>
                )}

                {self.state.notifications.map(function (not, i) {
                    return (
                        <div key={i}
                             className={"card shadow rounded-2 my-1 notification " + self.readedClass(not.read)}>
                            <div className="d-flex flex-row justify-content-between mb-2 my-1">
                                {not.unreadCount > 0 && (<div className="my-auto m-1 text-align-left">
                                    <span className="badge bg-primary">{not.unreadCount} yeni bildirim</span>
                                </div>)}
                                <div className="my-auto flex-grow-1 messageDate my-1 mx-2">
                                    <span className="badge rounded-pill bg-danger">{not.createdAtString}</span>
                                </div>
                            </div>
                            <div className="d-flex flex-row text-align-left mb-2">
                                {(not.trigger) &&
                                (
                                    <div className="ms-2 my-auto">
                                        <ProfilePicMobile
                                            userId={not.trigger.id}
                                            profilePicName={not.trigger.profilePicName}
                                            cssClass={"profilePicSmallMobile"}
                                        />
                                    </div>
                                )}
                                <div className="mx-2 my-auto">
                                    {(not.trigger) &&
                                    (<div className={"notificationUserName"}>
                                            <UserFullNameMobile
                                                user={not.trigger}
                                            /><br/>
                                        </div>
                                    )}
                                    {(not.notificationType === "REQUEST") &&
                                    self.newRequestText(not.message)
                                    }
                                    {(not.notificationType === "MESSAGE") &&
                                    self.newMessageText(not.trigger.id, not.id)
                                    }
                                    {(not.notificationType === "FOLLOW") &&
                                    self.newFollowText()
                                    }
                                    {(not.notificationType === "MESSAGE_ACTIVITY") &&
                                    self.newMessageActivityText(not.message, not.id)
                                    }
                                    {(not.notificationType === "REMINDER") &&
                                    self.newReminderText(not.message)
                                    }
                                    {(not.notificationType === "REVIEW") &&
                                    self.newReviewText(not.message)
                                    }
                                    {(not.notificationType === "INVITATION") &&
                                    self.newInvite(not.message)
                                    }
                                    {(not.notificationType === "REQUEST_APPROVAL") &&
                                    self.newRequestApprovalText(not.message)
                                    }
                                    {(not.notificationType === "NEW_ACTIVITY") &&
                                    self.newActivityText(not.message)
                                    }
                                    {(not.notificationType === "MEETING_COMMENT_AVAILABLE") &&
                                    self.newMeetingCommentAvailable(not.message)
                                    }
                                </div>
                            </div>
                        </div>
                    );
                })}

                <br/>
                <br/>
                <br/>
            </div>
        )
    }
}

export default NotificationsMobile;
