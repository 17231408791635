import React from "react";
import Security from "../../../security/Security";
import UserUtil from "../../../util/UserUtil";
import Globals from "../../../util/Globals";
import StarRatings from 'react-star-ratings';
import CompleteProfile from "../../../pcComponents/common/CompleteProfile";
import classnames from "classnames";

const axios = require('axios');

class OtherProfileMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            surname: "",
            gender: " ",
            profilePicName: "",
            isReviewedBefore: false,
            isFollowing: false,
            isBlocked: false,
            about: "",
            age: "",
            attendPercent: null,
            motivation: "",
            interestsArray: [],
            premiumType: false,
            errors: {},
            haveTheseUsersEverMeet: false,
            vibe: null,
            myVibeOfThisUser: null,
            myPointOfThisUser: 0,
            vibePercent: 0,
            vibePercentStart: 0,
            vibePercentEnd: 0,
            vibeCount: 0,
            followerCount: 0,
            myCommentOfThisUser: "",
            vibePoint: 0
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.follow = this.follow.bind(this);
        this.block = this.block.bind(this);
        this.onVibeChanged = this.onVibeChanged.bind(this);
        this.haveTheseUsersEverMeet = this.haveTheseUsersEverMeet.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.haveTheseUsersEverMeet();
        this.fillPage();
    }

    haveTheseUsersEverMeet() {
        let self = this;
        if (Security.isValidToken()) {
            if (localStorage.getItem("userId") !== this.props.match.params.id) {
                axios.get(Globals.serviceUrl + 'vibe/haveTheseUsersEverMeet/' + this.props.match.params.id, Security.authHeader())
                    .then(function (response) {
                        self.setState({"haveTheseUsersEverMeet": response.data});
                        axios.get(Globals.serviceUrl + 'vibe/myVibeOfThisUser/' + self.props.match.params.id, Security.authHeader())
                            .then(function (response) {
                                self.setState({"myVibeOfThisUser": response.data});
                            });

                        axios.get(Globals.serviceUrl + 'vibe/myPointOfThisUser/' + self.props.match.params.id, Security.authHeader())
                            .then(function (response) {
                                if (response.data !== "")
                                    self.setState({"myPointOfThisUser": response.data});
                            });

                        axios.get(Globals.serviceUrl + 'vibe/myCommentOfThisUser/' + self.props.match.params.id, Security.authHeader())
                            .then(function (response) {
                                self.setState({"myCommentOfThisUser": response.data});
                            });
                    });
            }
        }
    }

    fillPage() {
        let self = this;
        let userId = this.props.match.params.id;

        axios.get(Globals.serviceUrl + 'user/profile/' + userId)
            .then(function (response) {
                self.setState(response.data);
                self.setState({"name": response.data.name});
                self.setState({"surname": response.data.surname});

                self.setState({"gender": UserUtil.translateGender(self.state.gender)});
                self.setState({"profilePicName": response.data.profilePicName});

                self.setState({interestsArray: response.data.categories});
            });

        axios.get(Globals.serviceUrl + 'vibe/vibeCountOfUser/' + userId, Security.authHeader())
            .then(function (response) {
                self.setState({vibeCount: response.data});
            });

        if (Security.isValidToken())
            axios.get(Globals.serviceUrl + 'follow/isFollowing/' + userId, Security.authHeader())
                .then(function (response) {
                    self.setState({"isFollowing": response.data});
                });
        if (Security.isValidToken())
            axios.get(Globals.serviceUrl + 'block/isBlockedIt/' + userId, Security.authHeader())
                .then(function (response) {
                    self.setState({"isBlocked": response.data});
                });
        if (Security.isValidToken()) {
            axios.get(Globals.serviceUrl + 'vibe/vibePercent/' + userId, Security.authHeader())
                .then(function (response) {
                    if (response.data >= 4.5 && response.data <= 5) {
                        self.setState({"vibePercentStart": 4.5});
                        self.setState({"vibePercentEnd": 5.0});
                    } else if (response.data >= 4.0 && response.data < 4.5) {
                        self.setState({"vibePercentStart": 4.0});
                        self.setState({"vibePercentEnd": 4.5});
                    } else if (response.data >= 3.5 && response.data < 4.0) {
                        self.setState({"vibePercentStart": 3.5});
                        self.setState({"vibePercentEnd": 4.0});
                    } else if (response.data >= 3.0 && response.data < 3.5) {
                        self.setState({"vibePercentStart": 3.0});
                        self.setState({"vibePercentEnd": 3.5});
                    } else if (response.data >= 2.5 && response.data < 3.0) {
                        self.setState({"vibePercentStart": 2.5});
                        self.setState({"vibePercentEnd": 3.0});
                    } else if (response.data >= 2.0 && response.data < 2.5) {
                        self.setState({"vibePercentStart": 2.0});
                        self.setState({"vibePercentEnd": 2.5});
                    } else if (response.data >= 1.5 && response.data < 2.0) {
                        self.setState({"vibePercentStart": 1.5});
                        self.setState({"vibePercentEnd": 2.0});
                    } else if (response.data >= 1.0 && response.data < 1.5) {
                        self.setState({"vibePercentStart": 1.0});
                        self.setState({"vibePercentEnd": 1.5});
                    } else if (response.data >= 0.5 && response.data < 1.0) {
                        self.setState({"vibePercentStart": 0.5});
                        self.setState({"vibePercentEnd": 1});
                    } else if (response.data > 0.0 && response.data < 0.5) {
                        self.setState({"vibePercentStart": 0.0});
                        self.setState({"vibePercentEnd": 0.5});
                    }

                    self.setState({"vibePercent": response.data});
                });
        }

    }

    follow() {
        const self = this;
        axios.get(Globals.serviceUrl + 'follow/follow/' + this.props.match.params.id, Security.authHeader())
            .then(function (response) {
                self.setState({"isFollowing": response.data});
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });
    }

    block() {
        const self = this;
        if (!this.state.isBlocked) {
            if (window.confirm("Bu kişiyi engellemek istediğinizden emin misiniz?"))
                axios.get(Globals.serviceUrl + 'block/block/' + this.props.match.params.id, Security.authHeader())
                    .then(function (response) {
                        self.setState({"isBlocked": response.data});
                    }).
                    catch(function (error) {
                    self.setState({"errors": error.response.data});
                });
        }

        if (this.state.isBlocked) {
            if (window.confirm("Bu kişinin engelini kaldırmak istediğinizden emin misiniz?"))
                axios.get(Globals.serviceUrl + 'block/block/' + this.props.match.params.id, Security.authHeader())
                    .then(function (response) {
                        self.setState({"isBlocked": response.data});
                    }).
                catch(function (error) {
                    self.setState({"errors": error.response.data});
                });
        }
    }


    sendMessageButton() {
        if (this.props.match.params.id !== localStorage.getItem("userId")) {
            return (
                <a href={"/message/" + this.props.match.params.id} className={"full-width"}>
                    <button className={"btn btn-menuColorMobile profileButton"}><strong><i
                        className="far fa-comment"/></strong> Mesaj
                    </button>
                </a>)
        }
    }

    complainButton() {
        if (this.props.match.params.id !== localStorage.getItem("userId")) {
            return (
                <a className={"full-width"} href={"/complain/" + this.props.match.params.id}>
                    <button className={"btn btn-menuColorMobile profileButton"}><i className="far fa-angry"></i> Şikayet
                        et
                    </button>
                </a>
            )
        }
    }


    reviewButton() {
        if (this.props.match.params.id !== localStorage.getItem("userId")) {
            return (
                <a href={"/reviewForm/" + this.props.match.params.id} className={"full-width"}>
                    <button className={"btn btn-menuColorMobile profileButton"}><strong><i
                        className="far fa-edit"/></strong> Yorum
                        Yaz
                    </button>
                </a>
            )
        }
    }


    followButton() {
        if (this.state.isFollowing && this.props.match.params.id !== localStorage.getItem("userId")) {
            return (
                <div className={"full-width"}>
                    <button onClick={this.follow} className={"btn btn-menuColorMobile profileButton "}><strong>
                        <i className="far fa-bell-slash"/>
                    </strong> Takibi Bırak
                    </button>
                </div>
            )
        }

        if (!this.state.isFollowing && this.props.match.params.id !== localStorage.getItem("userId")) {
            return (<div className={"full-width"}>
                    <button onClick={this.follow} className={"btn btn-menuColorMobile profileButton"}><strong><i
                        className="far fa-bell"/></strong> Takip Et
                    </button>
                </div>
            )
        }
    }


    blockButton() {
        if (this.props.match.params.id !== localStorage.getItem("userId") && !this.state.isBlocked) {
            return (
                <div className={"full-width"}>
                    <button onClick={this.block}
                            className={"btn btn-menuColorMobile profileButton"}><strong><i
                        className="fas fa-ban"/></strong> Engelle
                    </button>
                </div>
            )
        }
        if (this.props.match.params.id !== localStorage.getItem("userId") && this.state.isBlocked) {
            return (
                <div className={"full-width"}>
                    <button onClick={this.block}
                            className={"btn btn-menuColorMobile profileButton"}><strong><i
                        className="fas fa-ban"/></strong> Engeli
                        Kaldır
                    </button>
                </div>
            )
        }

    }

    onVibeChanged(vibeType) {
        let self = this;
        let vibeDto = {};
        vibeDto.readerId = this.props.match.params.id;
        vibeDto.vibeType = vibeType;
        vibeDto.writerId = null;
        vibeDto.point = null;

        axios.post(Globals.serviceUrl + 'vibe/save/', vibeDto, Security.authHeader())
            .then(function (response) {
                self.setState({myVibeOfThisUser: vibeType})
            });
    }

    onVibePointChanged(vibePoint) {
        this.setState({vibePoint: vibePoint})
        let self = this;
        self.setState({myPointOfThisUser: vibePoint})
        /* let self = this;
         let vibeDto = {};
         vibeDto.readerId = this.props.match.params.id;
         vibeDto.vibeType = null;
         vibeDto.writerId = null;
         vibeDto.point = vibePoint;

         axios.post(Globals.serviceUrl + 'vibe/save/', vibeDto, Security.authHeader())
             .then(function (response) {
                 self.setState({myPointOfThisUser: vibePoint})
             });*/
    }

    onChange(e) {
        this.setState({myCommentOfThisUser: e.target.value});
    }


    changeRating(newRating, name) {
        this.setState({myPointOfThisUser: newRating});
        let self = this;
        self.setState({myPointOfThisUser: newRating});
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.state.myPointOfThisUser == 1 && this.state.myCommentOfThisUser.length < 3) {
            window.alert("1 puan verdiğin kullanıcılar için açıklama yapmalısın!");
            return false;
        }

        if (!window.confirm("Oyunu kaydetmek istediğine emin misin?"))
            return false;

        let self = this;
        let vibeDto = {};
        vibeDto.readerId = this.props.match.params.id;
        vibeDto.vibeType = null;
        vibeDto.writerId = null;
        vibeDto.point = this.state.myPointOfThisUser;
        vibeDto.comment = this.state.myCommentOfThisUser;

        axios.post(Globals.serviceUrl + 'vibe/save/', vibeDto, Security.authHeader())
            .then(function (response) {
                window.alert("Oyun kaydedildi") //self.setState({myPointOfThisUser: this.state.vibePoint})
            });
    }

    render() {

        let {interestsArray} = this.state;

        return (
            <div className="full-width container" style={{'minHeight': '93vh'}}>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            KULLANICI PROFİLİ
                        </a>
                    </div>
                </nav>
                <br/>
                <div className="container-fluid card shadow my-1 rounded-2">
                    <div className="row mb-2">
                        <div className="col-3 my-3">
                            <img className={"profilePicMedium"}
                                 src={UserUtil.buildProfilePicUrl(this.state.profilePicName)}/>
                        </div>

                        <div className="col-9 m-auto text-end">
                            <a className="userFullName ms-2" href={"/profile/" + this.props.match.params.id}>
                                <strong>
                                    {/*{this.state.premiumType === "GOLD" && (*/}

                                    {/*    <span className={'goldCheck'}><i className="far fa-check-circle"/>&nbsp;</span>*/}
                                    {/*)}*/}
                                    {/*{this.state.premiumType === "SILVER" && (*/}

                                    {/*    <span className={'silverCheck'}><i className="far fa-check-circle"/>&nbsp;</span>*/}
                                    {/*)}*/}
                                    {/*{this.state.premiumType === "ORGANIZATOR" && (*/}

                                    {/*    <span className={'proCheck'}><i className="fas fa-certificate"/>&nbsp;</span>*/}
                                    {/*)}*/}
                                    {this.state.name + " " + this.state.surname}</strong>
                            </a>
                            <br/>
                            <strong>{this.state.gender} / {this.state.age} / </strong>
                            <strong><i className="fas fa-user"/> {this.state.followerCount}</strong>
                            {/*<h6>{this.state.point} <i className="far fa-star"/></h6>*/}
                            <div className="mt-2">
                                <div className="mt-2">
                                    {this.state.vibePercentEnd > 0 && (<StarRatings
                                        rating={this.state.vibePercent}
                                        starRatedColor="#198754"
                                        starDimension="24px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                    />)}
                                    <strong>({this.state.vibeCount} oy)
                                        {(localStorage.getItem("userId") === "3212") && (this.state.vibePercent)}
                                        {this.state.vibePercent === 0 && (
                                            <span>Yeterli veri yok!</span>
                                        )}
                                    </strong>
                                </div>
                            </div>
                        </div>

                        {this.state.attendPercent != null && (
                            <div className="col-12">
                                <div>
                                    Onaylandığı aktivitelere katılım oranı
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar"
                                             style={{width: this.state.attendPercent + '%'}} aria-valuenow="50"
                                             aria-valuemin="0" aria-valuemax="100">{this.state.attendPercent}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>


                    <div className="row">
                        <div className="col-6 px-0">
                            {this.sendMessageButton()}
                        </div>
                        <div className="col-6 px-0">
                            {this.blockButton()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 px-0">
                            {this.followButton()}
                        </div>
                        <div className="col-6 px-0">
                            {this.complainButton()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 px-0">
                            {this.reviewButton()}
                        </div>
                        <div className="col-6 px-0">
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-4 px-0">
                            <div className="profileTitleMobileDiv p-2 m-1">
                                <a className="profilePhotoReviewActivity" href={"/album/" + this.props.match.params.id}>
                                    Fotoğraflar
                                </a>
                            </div>
                        </div>
                        <div className="col-4 px-0">
                            <div className="profileTitleMobileDiv p-2 m-1">
                                <a className="profilePhotoReviewActivity"
                                   href={"/reviews/" + this.props.match.params.id}>
                                    Yorumlar
                                </a>
                            </div>
                        </div>
                        <div className="col-4 px-0">
                            <div className="profileTitleMobileDiv p-2 m-1">
                                <a className="profilePhotoReviewActivity"
                                   href={"/userActivities/" + this.props.match.params.id}>
                                    Aktiviteler
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <br/>
                {this.state.haveTheseUsersEverMeet && (
                    <div className={"orange-card card shadow rounded-2 vibeQuestionContainer"}>
                        <div className="card-header">
                            <strong>Bu kişi sende nasıl bir izlenim bıraktı? </strong><br/>
                            (Bu cevabı yalnız sen görebilirsin ve istediğin zaman değiştirebilirsin) <br/>
                            {(this.state.myVibeOfThisUser === "POSITIVE") && <strong>Eski oyun : Olumlu</strong>}
                            {(this.state.myVibeOfThisUser === "NEGATIVE") && <strong>Eski oyun : Olumsuz</strong>}
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.onSubmit}>
                                <StarRatings
                                    rating={this.state.myPointOfThisUser}
                                    starRatedColor="#198754"
                                    starHoverColor="#0d6efd"
                                    starDimension="40px"
                                    starSpacing="12px"
                                    numberOfStars={5}
                                    changeRating={(newRating, name) => this.changeRating(newRating, name)}
                                />

                                <div className="form-group">
                                    <textarea
                                        className={classnames("form-control breakLine mt-3")}
                                        placeholder={"Verdiğin puanı açıklamak ister misin? Bu açıklamanı sadece uygulama yöneticisi görecek."}
                                        name="comment"
                                        rows="3"
                                        value={this.state.myCommentOfThisUser}
                                        onChange={this.onChange} maxLength={500}/>
                                </div>
                                <button type="submit"
                                        name="button"
                                        className="btn btn-primary btn-block mt-2 px-5">Kaydet</button>
                            </form>
                        </div>
                    </div>
                )}
                <br/>
                {(localStorage.getItem("userId") === "3212")
                && (
                    <div className={"full-width"}>
                        <a href={"/uhktybb/police"} className={"float-left"}>
                            <button className={"btn btn-danger"}>Kullanıcı(id:{this.props.match.params.id})
                            </button>
                        </a>
                        <div className={"clear-both"}/>
                    </div>
                )}

                <div className="orange-card card shadow rounded-2 mb-3">
                    <div className="card-header">
                        <h5 className="card-title">Hakkımda</h5>
                    </div>
                    <div className="card-body">
                        <span className={"breakLine"}>
                                        {this.state.about}
                                    </span>
                    </div>
                </div>

                <div className="orange-card card shadow rounded-2 mb-3">
                    <div className="card-header">
                        <h5 className="card-title">İlgi Alanlarım</h5>
                    </div>
                    <div className="card-body">
                        {
                            interestsArray.map(function (interest) {
                                if (interest !== "")
                                    return (<a href={"/categoryDetail/" + interest.id}>
                                            <span
                                                className="btn-secondary badge my-interestsMobile">{interest.name}</span></a>
                                    )
                            })
                        }
                    </div>
                </div>

                <div className="orange-card card shadow rounded-2 mb-3">
                    <div className="card-header">
                        <h5 className="card-title">Şunları önerebilirim?</h5>
                    </div>
                    <div className="card-body">
                        <span className={"breakLine"}>
                                    {this.state.motivation}
                                    </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default OtherProfileMobile;
