import ProfilePicMobile from "./ProfilePicMobile";
import UserFullNameMobile from "./UserFullNameMobile";
import ActivityInfoBlockMobile from "./ActivityInfoBlockMobile";
import ActivityEditButtonsMobile from "./ActivityEditButtonsMobile";
import ActivityRequestButtonsMobile from "./ActivityRequestButtonsMobile";
import React from "react";


class ActivityListItemMobile extends React.Component {
    constructor(props) {
        super(props)
    }


    render() {

        let selectedClass = "";
        // if (this.props.activity.id === 2545)
        //     selectedClass = "selectedActivity";


        return (
            <div key={this.props.i} className={"orange-card card shadow rounded-2 mt-2" + selectedClass}>
                <div className="card-header rounded-2 py-0 px-2 m-0 text-align-left row">

                    <div className="col-2 p-1">
                        <ProfilePicMobile
                            userId={this.props.activity.profileDto.id}
                            profilePicName={this.props.activity.profileDto.profilePicName}
                            cssClass={"profilePicSmallMobile"}
                        />
                    </div>
                    <div className="col-6 p-1 m-auto">
                        <UserFullNameMobile
                            user={this.props.activity.profileDto}
                        />
                    </div>

                    <div className="col-4 py-0 px-1 m-auto"
                         style={{'textAlign': 'right', 'color': '#dc3545', 'fontWeight': 600}}>
                        <span> {this.props.activity.deadLineString}</span>
                    </div>
                </div>

                <div className={"activityListDetailContainerMobile card-body text-align-left full-width"}>

                    {/*                    {this.props.activity.profileDto.socialScore>0 &&(*/}
                    {/*                        <div className="progress" style={{width:"50%"}}>*/}
                    {/*                            <div className="progress-bar-striped bg-info" role="progressbar"*/}
                    {/*                                 style={{width: (this.props.activity.profileDto.socialScore /10)+ '%',color:"white"}} aria-valuenow={this.props.activity.profileDto.socialScore}*/}
                    {/*                                 aria-valuemin="0" aria-valuemax="1000">{" "+this.props.activity.profileDto.socialScore}*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    )}*/}
                    {/*                    {this.props.activity.profileDto.socialScore===-1 && this.props.activity.profileDto.id!== 3212 &&(*/}
                    {/*                        <h6><strong><i className="fas fa-glass-cheers"/></strong>Yeterli veri yok</h6>*/}
                    {/*x*/}
                    {/*                    )}*/}
                    <ActivityInfoBlockMobile
                        key={this.props.i}
                        photoName={this.props.activity.photoName}
                        detail={this.props.activity.detail}
                        categories={this.props.activity.categories}
                    />
                </div>

                <div className="card-footer">
                    <div className="d-flex flex-row">
                        {this.props.activity.thisUserLiked ?
                            (<button onClick={() => this.props.likeActivity(this.props.activity.id)}
                                     className={"btn activityMessageMobile me-2 btn-danger "}>
                                <span> <i className="far fa-heart"/> {this.props.activity.likeCount}</span>
                            </button>) :
                            (<button onClick={() => this.props.likeActivity(this.props.activity.id)}
                                     className={"btn activityMessageMobile me-2 btn-outline-danger heart-button"}>
                                <span> <i className="far fa-heart"/> {this.props.activity.likeCount}</span>
                            </button>)}
                        <ActivityRequestButtonsMobile
                            userId={this.props.activity.profileDto.id}
                            joinActivity={() => this.props.joinActivity(this.props.activity.id)}
                            thisUserJoined={this.props.activity.thisUserJoined}
                        />
                        {(this.props.activity.thisUserJoined === 2 || this.props.activity.profileDto.id === parseInt(localStorage.getItem("userId"))) &&
                       <>
                        <a href={"/messageActivity/" + this.props.activity.id}>
                            <button className="btn btn-info activityMessageMobile me-2">
                            <span>
                                <i className="fas fa-envelope text-white"/></span>
                            </button>
                        </a>
                        <a href={"/activityDetail/" + this.props.activity.id}>
                            <button className="btn btn-warning activityMessageMobile me-2">
                            <span>
                                <i className="fas fa-users text-white"/></span>
                            </button>
                        </a>
                        </>
                        }
                    </div>
                    <div className="d-flex flex-row-reverse mt-2">
                        {(!this.props.activity.expired) &&
                        (
                            <ActivityEditButtonsMobile
                                activityId={this.props.activity.id}
                                userId={this.props.activity.profileDto.id}
                                deleteActivity={() => this.props.deleteActivity(this.props.activity.id)}/>
                        )
                        }
                        {(this.props.activity.expired) &&
                        (<a href={"/activityDetail/" + this.props.activity.id} className={"float-right"}>
                            <button className={"btn btn-secondary"}><i className="fas fa-users"/>Katılanlar
                            </button>
                        </a>)
                        }
                        {(localStorage.getItem("userId") === "3212") &&
                        (
                            <div className={"full-width"}>
                                <a href={"/uhktybb/police"} className={"float-left"}>
                                    <button className={"btn btn-danger"}>({this.props.activity.profileDto.id})
                                    </button>
                                </a>
                                <a href={"/activityRequests/" + this.props.activity.id} className={"float-left"}>
                                    <button className={"btn btn-primary float-left"}>Akt</button>
                                </a>
                                <div className={"clear-both"}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )

    }
}


export default ActivityListItemMobile;


