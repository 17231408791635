import React, {Component} from "react";
import classnames from "classnames";
import security from "../../../security/Security";
import Globals from "../../../util/Globals";

const axios = require('axios');

class VerifyPhone extends Component {
    constructor() {
        super();

        if (localStorage.getItem("jwtToken")) {
            security.logout();
        }
        this.state = {
            code: "",
            isSubmitDisabled: false,
            errors: {}
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    verify(code) {
        const self = this;

        axios.get(Globals.serviceUrl + "user/verifyMobile/"+code)
            .then(function (res) {
                alert("Kaydınız tamamlandı, kullanıcı adınız ve şifreniz ile giriş yapabilirsiniz.");
                window.location="/login"
            }).catch(function (error) {
            self.setState({isSubmitDisabled: false});
            alert("Kodu yanlış girdiniz, lütfen tekrar deneyin!");
        });
    };

    onSubmit(e) {
        e.preventDefault();
        this.setState({isSubmitDisabled: true});

        this.verify(this.state.code);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <div className="container-fluid" style={{'backgroundColor': '#ffe5d0', 'height': '100vh'}}>
                <div className="d-flex justify-content-center" style={{'height': '100vh'}}>
                    <div className="user_card">
                        <div className="d-flex justify-content-center">
                            <div className="brand_logo_container">
                                <img src={"/img/ioslogo3.png"}
                                     className="brand_logo" alt="Logo"/>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center form_container">
                            <p className="mb-2 color-white"><strong>Telefonunuza doğrulama kodu gönderdik.</strong></p>
                            <form onSubmit={this.onSubmit}>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text" style={{'height': '100%'}}><i
                                            className="fas fa-keyboard"/></span>
                                    </div>
                                    <input
                                        type="text"
                                        className={classnames("form-control input_user")}
                                        placeholder="Doğrulama Kodu"
                                        name="code"
                                        value={this.state.code}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="d-flex justify-content-center mt-3 login_container">
                                    <button type="submit" name="button" className="btn login_btn"
                                            disabled={this.state.isSubmitDisabled}>Onayla
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="mt-3">
                            <div className="d-flex flex-column justify-content-center links mb-2">
                                <div className="alert alert-danger bg-white full-width py-0">
                                    Eğer kodu almakta sorun yaşıyorsan instagramdan bize yaz. Çözeriz :)
                                </div>
                                <div className="d-flex">
                                    <a href="https://www.instagram.com/actizonetr/">
                                        <img className="shadow rounded-5 mb-3 me-3" width="150px"
                                             src="/img/instagram.png"
                                             alt="https://www.instagram.com/actizonetr/"/>
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.alinso.activity&hl=tr">
                                        <img className="shadow rounded-2 mb-3 me-3" width="150px"
                                             src="/img/google_play.png"
                                             alt="https://play.google.com/store/apps/details?id=com.alinso.activity&hl=tr"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VerifyPhone;
