import React from "react";
import Security from "../../security/Security";
import Globals from "../../util/Globals";
import ProfilePic from "../../pcComponents/common/ProfilePic";
import UserFullName from "../../pcComponents/common/UserFullName";
import UserUtil from "../../util/UserUtil";
import ProfilePicMobile from "../common/ProfilePicMobile";
import UserFullNameMobile from "../common/UserFullNameMobile";
import security from "../../security/Security";
import StarRatings from 'react-star-ratings';

const axios = require('axios');


class UsersICanVibe extends React.Component {
    constructor(props) {
        super(props);
        Security.protect();

        this.state = {
            profileDtos: [],
        };

        this.fillPage();
    }


    fillPage() {
        let self = this;
        axios.get(Globals.serviceUrl + 'vibe/usersICanVibe', Security.authHeader())
            .then(function (response) {
                self.setState({"errors": {}});
                self.setState({profileDtos: response.data});
            })
            .catch(function (error) {
                self.setState({"errors": error.response.data});
            });
    }

    onVibeChanged(vibeType, readerId) {
        let self = this;
        let vibeDto = {};
        vibeDto.readerId = readerId;
        vibeDto.vibeType = vibeType;
        vibeDto.writerId = null;

        axios.post(Globals.serviceUrl + 'vibe/save/', vibeDto, Security.authHeader())
            .then(function (response) {
                self.fillPage();
            });
    }

    deleteVibe(readerId) {
        if (!window.confirm("Bu yorumu kalıcı olarak silmek istiyor musunuz?"))
            return;

        let self = this;
        let vibeDto = {};
        vibeDto.readerId = readerId;
        vibeDto.writerId = null;


        axios.post(Globals.serviceUrl + "vibe/delete", vibeDto, Security.authHeader())
            .then((function (response) {
                self.fillPage();
            }));
    }

    render() {

        let self = this;
        return (
            <div className="container-fluid" style={{'minHeight': '93vh'}}>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top mb-2">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            VERDİĞİM OYLAR
                        </a>
                    </div>
                </nav>

                <div className="alert alert-info">
                    Bu liste oy verebileceğin tüm kullanıcıları gösteriyor. Lütfen bu kişilerden tanıdıkların için puan
                    ver, böylece aktiviteler öncesi
                    diğer insanlar da fikir sahibi olabilirler. Merak etme, verdiğin cevabı yalnız sen görebilirsin ve
                    dilediğin zaman değiştirebilirsin. Lütfen sadece
                    tanıdığın kişilere oy ver ve oy verirken 2 kere düşün :)
                </div>

                {self.state.profileDtos.map((user, i) => {

                    return (
                        <div className="card d-flex flex-row m-1">
                            <div className="col-4 mt-2">
                                <div className="row">
                                    <div className="col-12">
                                        <ProfilePicMobile
                                            userId={user.id}
                                            profilePicName={user.profilePicName}
                                            cssClass={"profilePicSmallMobile"}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <strong>{/*
                                            {user.userPremium && (
                                                <span><i className="far fa-check-circle"/>&nbsp;</span>
                                            )}*/}
                                            {user.name + " " + user.surname}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 mt-2">
                                {(user.myVibe !== null && user.myVibe === "POSITIVE") && (
                                    <strong>Verdiğin oy : Olumlu<br/></strong>)}
                                {(user.myVibe !== null && user.myVibe === "NEGATIVE") &&
                                <strong>Verdiğin oy : Olumsuz<br/></strong>}
                                {(user.myVibePoint !== null && user.myVibePoint > 0) && (<StarRatings
                                    rating={user.myVibePoint}
                                    starRatedColor="green"
                                    starDimension="25px"
                                    starSpacing="5px"
                                    numberOfStars={5}
                                />)}
                                {(user.myVibePoint !== null && user.myVibePoint > 0) && (<br/>)}
                                {(user.myVibeComment !== null) &&
                                <span><strong>Yaptığın yorum :</strong> {user.myVibeComment} <br/></span>}

                                {/*<div className={"half-left text-align-left"}>*/}
                                {/*    <div className="form-group">*/}
                                {/*        <input type="radio"*/}
                                {/*               name={"myVibeOfThisUser"+user.id}*/}
                                {/*               checked={user.myVibe=="POSITIVE"}*/}
                                {/*               onChange={() => this.onVibeChanged("POSITIVE",user.id)}*/}
                                {/*               className="customRadio"*/}
                                {/*        />*/}
                                {/*        <label className="customRadioLabelMobile">Olumlu&nbsp;</label>*/}
                                {/*        <br/>*/}
                                {/*        <input type="radio"*/}
                                {/*               name={"myVibeOfThisUser"+user.id}*/}
                                {/*               onChange={() => this.onVibeChanged("NEGATIVE",user.id)}*/}
                                {/*               checked={user.myVibe==="NEGATIVE"}*/}
                                {/*               className="customRadio"*/}
                                {/*        />*/}
                                {/*        <label className="customRadioLabelMobile">Olumsuz&nbsp;</label>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {(user.myVibe !== null || user.myVibePoint > 0) &&
                                (
                                    <div>
                                        <button type="button" className={"btn btn-danger profileButton"}
                                                onClick={() => self.deleteVibe(user.id)}>
                                            <i className=" fas fa-times"/> Sil
                                        </button>
                                    </div>
                                )}
                            </div>

                        </div>
                    )
                })}
            </div>

        )
    }
}

export default UsersICanVibe;
