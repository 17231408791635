import React from "react";
import Security from "../../../security/Security";
import ProfilePicMobile from "../../common/ProfilePicMobile";
import UserFullNameMobile from "../../common/UserFullNameMobile";
import JSUtil from "../../../util/JSUtil";
import Globals from "../../../util/Globals";
import UserUtil from "../../../util/UserUtil";

const axios = require('axios');


class FollowersMobile extends React.Component {
    constructor() {
        super();
        Security.protect()

        this.state = {
            followings: [],
            pageNum:0,
            noMoreRecords:false
        };


        this.fillPage=this.fillPage.bind(this);
        this.loadMore=this.loadMore.bind(this);
        this.fillPage(0);
        let self = this;
        window.onscroll = function (ev) {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                self.loadMore();
            }
        };
    }

    loadMore() {
        let newPageNum = this.state.pageNum + 1;
        this.setState({pageNum: newPageNum});
        this.fillPage(newPageNum);
    }

    fillPage(pageNum) {
        const self = this;
        axios.get(Globals.serviceUrl+'follow/myFollowers/'+pageNum, Security.authHeader())
            .then(function (response) {

                if(response.data.length==0){
                    self.setState({noMoreRecords:true});
                    return;
                }

                let newFollowers = self.state.followings;
                newFollowers = newFollowers.concat(response.data);
                self.setState({followings: newFollowers});

            })
            .catch(function (error) {
                console.log(error.response);
            });

    }


    render() {
        const self = this;
        return (
            <div className="full-width container" style={{'minHeight': '93vh'}}>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            TAKİPÇİLERİM
                        </a>
                    </div>
                </nav>
                <div>{self.state.followings.length===0 && (
                    <div className="col-12 alert alert-info mt-3 mb-0">
                       <span>
                         Henüz bir takipçin yok!
                    </span>
                    </div>)}

                </div>
                {
                    self.state.followings.map(function (following, i) {
                        return (<div className="card shadow rounded-2 my-1 searchItemContainer">
                            <div className="d-flex flex-row text-align-left mb-2">
                                <div className="ms-2 mt-2 my-auto">
                                    <ProfilePicMobile
                                        userId={following.id}
                                        profilePicName={following.profilePicName}
                                        cssClass={"profilePicSmallMobile"}
                                    />
                                    <br/>

                                </div>
                                <div className="mx-2 my-auto">
                                    <UserFullNameMobile
                                        user={following}
                                    />
                                    <br/>
                                    <span>{UserUtil.translateGender(following.gender)} / {following.age}  </span>
                                </div>
                            </div>
                        </div>);
                    })
                }
                <br/>
                <button hidden={this.state.noMoreRecords}  className={"btn btn-secondary"} onClick={this.loadMore}>Daha
                    fazla göster...
                </button>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>

        )
    }
}

export default FollowersMobile;
