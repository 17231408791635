import React from "react";
import Security from "../../../security/Security";
import ProfilePicMobile from "../../common/ProfilePicMobile";
import UserFullNameMobile from "../../common/UserFullNameMobile";
import JSUtil from "../../../util/JSUtil";
import Globals from "../../../util/Globals";

const axios = require('axios');


class BlocksMobile extends React.Component {
    constructor() {
        super();
        Security.protect()

        this.state = {
            blocks: [],
            erorrs: {}
        };

        this.fillPage();
    }

    fillPage() {
        const self = this;
        axios.get(Globals.serviceUrl+'block/myBlocks', Security.authHeader())
            .then(function (response) {
                self.setState({blocks: response.data});
            })
            .catch(function (error) {
                console.log(error.response);
            });

    }

    unblock(id, name) {
        const self = this;

        if (window.confirm(" Engellediğiniz, " + name + " üzerindeki engeli kaldıracaksınız, emin misiniz?"))
            axios.get(Globals.serviceUrl+'block/block/' + id, Security.authHeader())
                .then(function (response) {

                    let blocks = self.state.blocks;
                    JSUtil.deleteFromArrayByPropertyName(blocks, "id", id);
                    self.setState({blocks: blocks});
                })
                .catch(function (error) {
                });
    }


    render() {
        const self = this;
        return (
            <div className="full-width container" style={{'minHeight': '93vh'}}>
                <nav className="filter-navbar navbar navbar-light bg-secondary shadow rounded-3 sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#" style={{'color': '#fff', 'fontWeight': '600'}}>
                            ENGEL LİSTEM
                        </a>
                    </div>
                </nav>
                <div>{self.state.blocks.length===0 && (
                    <div className="col-12 alert alert-info mt-3 mb-0">
                       <span>
                         Henüz engellediğin birisi yok!
                    </span>
                    </div>)}

                </div>
                {
                    self.state.blocks.map(function (block, i) {
                        return (
                            <div  className="card shadow rounded-2 my-1 searchItemContainer">
                                <div className="d-flex flex-row  mb-2">
                                    <div className="col-6 ms-2 mt-2 my-auto">
                                    <ProfilePicMobile
                                        userId={block.id}
                                        profilePicName={block.profilePicName}
                                        cssClass={"profilePicSmallMobile"}
                                    />
                                    <br/>
                                    <UserFullNameMobile
                                        user={block}
                                    />
                                    </div>
                                    <div className="col-6 mx-2 my-auto"><br/>
                                        <button onClick={() => self.unblock(block.id, block.name)}
                                                className={"btn btn-danger"}>Engeli Kaldır
                                        </button>
                                    </div>
                                    <hr/>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        )
    }
}

export default BlocksMobile;
